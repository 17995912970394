import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

const voidText = '-';

const ContactPolicy: StrictTypedTypePolicies['Contact'] = {
  fields: {
    displayName: {
      read(_, { readField }) {
        const firstname = readField<string | null>('firstname') || '';
        const lastname = readField<string | null>('lastname') || '';

        const fullName = `${firstname} ${lastname}`.trim();

        if (fullName.length !== 0) {
          return fullName;
        }

        const company = readField<string | null>('company') || '';

        return company || voidText;
      },
    },
  },
};

export default ContactPolicy;
