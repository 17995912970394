import { CustomStylishParams, GetAntdTheme } from 'antd-style';

import Colors from 'Themes/Colors';

export const customToken = {
  primaryTransparent: `rgba(255,166,0,0)`,
  primaryShadowColor: 'rgb(255 210 0 / 30%)',
  borderRadiusRounded: 9999,
  fontSizeXS: 12,
  colorSecondary: Colors.primaryMain,
  scrollBarColor: 'rgba(255,255,255,.3)',

  panelsActiveBackground: Colors.secondaryMain,
  panelsActiveBorderColor: Colors.secondaryMain,

  batchNotificationBackground: `linear-gradient(90deg, ${Colors.primaryOptional} 0%, ${Colors.primaryMain} 100%)`,
  batchNotificationColor: '#fff',

  storageCardBackground: `linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%)`,
  storageCardBorder: 'rgba(255,255,255,.5)',

  uploaderTitleBackground: `linear-gradient(90deg, ${Colors.primaryOptional} 0%, ${Colors.primaryMain} 100%)`,
  uploaderButtonBackground: 'rgba(255, 255, 255, 0)',
  uploaderButtonHoverBackground: 'rgba(255, 255, 255, 0.3)',
  uploaderButtonColor: '#fff',

  menuLeftButtonSize: 44,
  menuLeftBackgroundColor: '#27ADD3',
  menuLeftSeparatorColor: 'rgba(39, 173, 211, 0.5)',

  menuLeftButtonBackgroundColor: 'rgba(0,0,0,0)',
  menuLeftButtonBorderColor: 'rgba(255,255,255,.5)',
  menuLeftButtonColor: '#fff',

  menuLeftButtonHoverBackgroundColor: '#fff',
  menuLeftButtonHoverBorderColor: '#fff',
  menuLeftButtonHoverColor: '#FFA600',

  menuLeftButtonActiveBackgroundColor: '#FFA600',
  menuLeftButtonActiveBorderColor: '#FFA600',
  menuLeftButtonActiveColor: '#000',

  menuRightButtonBackgroundColor: '#FFE9BF',
  menuRightButtonColor: '#67717D',
  menuRightActionButtonColor: '#FFA600',
};

const breakpointsConfig = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const getBreakpointsTokens = (breakpoints: Record<string, number>) => {
  const breakpointNames = Object.keys(breakpoints);

  const breakPoints: Record<string, number> = {};

  breakpointNames.forEach((breakpointName, index) => {
    breakPoints[`screen${breakpointName}Min`] = breakPoints[breakpointName];
    breakPoints[`screen${breakpointName}`] = breakPoints[breakpointName];
    breakPoints[`screen${breakpointName}Max`] = breakpointNames[index + 1]
      ? breakPoints[breakpointNames[index + 1]] - 1
      : 9999;
  });

  return breakPoints;
};

export const theme: GetAntdTheme = appearence => ({
  token: {
    colorPrimary: Colors.secondaryMain,
    colorPrimaryBg: '#FFE9BF',
    colorInfo: Colors.secondaryMain,
    colorLink: Colors.secondaryMain,
    borderRadius: 16,
    borderRadiusLG: 24,
    colorSuccess: '#91cb00',
    colorWarning: Colors.secondaryMain,
    colorError: '#ff3333',
    colorTextBase: '#282a2d',
    colorTextSecondary: '#67717D',
    lineWidth: 2,
    lineWidthFocus: 2,
    fontFamily: "'Lato', sans-serif",
    // Breakpoints
    ...getBreakpointsTokens(breakpointsConfig),
  },
  components: {
    Button: {
      primaryColor: '#000',
      defaultHoverBg: Colors.secondaryMain,
      defaultHoverColor: 'rgb(0, 0, 0)',
      defaultBorderColor: Colors.secondaryMain,
      defaultHoverBorderColor: Colors.secondaryMain,
      defaultBg: 'rgba(255,255,255,0)',
      defaultColor: Colors.secondaryMain,
      linkDecoration: 'none',
      contentFontSize: 14,
      contentFontSizeLG: 14,
      paddingBlock: 8,
      paddingBlockLG: 8,
      paddingInline: 16,
      paddingInlineLG: 16,
    },
    Dropdown: {
      borderRadiusLG: 8,
    },
    Select: {
      fontSizeLG: 14,
      borderRadiusLG: 8,
      optionFontSize: 14,
    },
    Breadcrumb: {
      lastItemColor: Colors.primaryMain,
      linkHoverColor: Colors.primaryMain,
      borderRadiusSM: 24,
      paddingXXS: 8,
    },
    Checkbox: {
      colorPrimary: Colors.primaryMain,
      colorPrimaryBorder: Colors.primaryMain,
      colorPrimaryHover: Colors.primaryMain,
    },
    Spin: {
      colorPrimary: Colors.primaryMain,
    },
    Tree: {
      directoryNodeSelectedBg: 'rbga(0,0,0,0)',
      nodeHoverBg: 'rbga(0,0,0,0)',
      nodeSelectedBg: 'rbga(0,0,0,0)',
      directoryNodeSelectedColor: Colors.secondaryMain,
    },
    Table: {
      rowSelectedBg: `#f4f8fb`,
      rowSelectedHoverBg: `#f4f8fb`,
    },
    Notification: {
      zIndexPopup: 1000,
    },
    Input: {
      inputFontSize: 14,
      inputFontSizeLG: 14,
      paddingInline: 16,
      paddingInlineLG: 16,
      paddingBlock: 8,
      paddingBlockLG: 8,
    },
    InputNumber: {
      fontSize: 14,
      fontSizeLG: 14,
      paddingInline: 16,
      paddingInlineLG: 16,
      paddingBlock: 8,
      paddingBlockLG: 8,
    },
    Radio: {
      buttonSolidCheckedColor: '#000',
    },
    Progress: {
      defaultColor: Colors.secondaryMain,
    },
  },
});

export const customStylish = ({ css, token }: CustomStylishParams) => ({
  link: css`
    color: ${token.colorTextSecondary};
    text-decoration: none;
    font-weight: ${token.fontWeightStrong};
    &:hover {
      color: ${token.colorPrimary};
      text-decoration: underline;
    }
  `,
  text: css``,
  shadow: css`
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  `,
  shadowHover: css`
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  `,
  primaryShadow: css`
    box-shadow: 0 0 0 2px ${token.primaryShadowColor};
  `,
});

type CustomTokenType = typeof customToken;
type CustomStylishType = ReturnType<typeof customStylish>;

declare module 'antd-style' {
  export interface CustomToken extends CustomTokenType {}
  export interface CustomStylish extends CustomStylishType {}
}
