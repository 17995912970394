import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { App, Popconfirm, Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { compact } from 'lodash';

import Container from 'Components/Atoms/Container';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { AccessType, EditAccessFormPayload, EditAccessFormValues } from 'Forms/Access';
import { SendEmailPayload } from 'Forms/SendEmail';

import { Colors } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useCurrency } from 'Hooks/useCurrency';

import {
  EmailModelType,
  EmailTemplateAssociatedModel,
  GalleryAccessPolicy,
  GetAccessCodesQuery,
  SendEmailInput,
} from 'Operations/__generated__/graphql';

import { SEND_EMAIL } from 'Operations/Mutations/Email/SendEmail';

interface Props {
  total: number;
  isLoading: boolean;
  paginationSize?: number;
  data?: GetAccessCodesQuery['getAccessCodes']['edges'];
  type: AccessType;
  galleryId: number;
  galleryUrl: string;
  galleryContactId: number | undefined;
  galleryAccessPolicy: GalleryAccessPolicy;
  onEdit?: (data: EditAccessFormPayload) => Promise<void>;
  onDelete?: (data: GetAccessCodesQuery['getAccessCodes']['edges'][0]) => Promise<void>;
  onChange: (props: TablePaginationConfig) => void;
}
const StyledSpan = styled.span`
  color: ${Colors.black};
`;

const AccessTable = ({
  data,
  type,
  galleryUrl,
  galleryId,
  galleryContactId,
  galleryAccessPolicy,
  total,
  isLoading,
  paginationSize,
  onEdit,
  onDelete,
  onChange,
}: Props) => {
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();
  const { symbol } = useCurrency();
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const [sendEmail] = useMutation(SEND_EMAIL);

  const handleSendEmail = useCallback(
    async ({
      values: { attachmentName: _attName, attachmentUrl: _attUrl, ...values },
      formikBag,
    }: SendEmailPayload) => {
      try {
        const sendEmailInput: SendEmailInput = {
          contactId: values.contactId,
          content: values.content,
          modelId: galleryId,
          modelType: EmailModelType.GALLERYACCESSCODE,
          title: values.title,
          attachment: values.attachment,
          secondaryContactIds: values.secondaryContactIds,
          templateId: values.templateId,
        };
        await sendEmail({
          variables: { data: sendEmailInput },
        });

        formikBag.setSubmitting(false);
        closeModal();

        message.success(t('app.message.gallery.sendEmail.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, galleryId, sendEmail, t],
  );

  const copyToClipboard = useCallback(
    ({ value }: { value: string }) => {
      navigator.clipboard.writeText(value);
      message.success(t('app.common.clipboard.copy'));
    },
    [t],
  );

  const handleOpenEmailModal = useCallback(
    async (associatedModel: EmailTemplateAssociatedModel, contactId?: number) => {
      const defaultValues = {
        templateAssociatedModel: associatedModel,
        contactId: contactId ? contactId : galleryContactId,
      };

      openModal('SEND_EMAIL', {
        onSubmit: handleSendEmail,
        defaultValues,
        contactId,
      });
    },
    [galleryContactId, handleSendEmail, openModal],
  );

  const columns: ColumnsType<GetAccessCodesQuery['getAccessCodes']['edges'][0]> = useMemo(
    () =>
      compact([
        {
          title: t('app.common.actions'),
          key: 'operation',
          width: 150,
          render: (_, record) => (
            <Container key={record.code} gap={8}>
              <RoundButton
                icon="edit"
                tooltipTitle={t('app.access.editAccess')}
                onClick={() => {
                  const defaultValues: EditAccessFormValues = {
                    name: record.name ? record.name : '',
                    code: record.code,
                    oldCode: record.code,
                    accessType: type,
                  };
                  openModal('ACCESS_CODE', {
                    name: 'edit',
                    onSubmit: onEdit,
                    defaultValues,
                    galleryAccessPolicy,
                  });
                }}
              />
              <RoundButton
                onClick={() => {
                  window.open(`${galleryUrl}?accessCode=${record.code}`, '_blank');
                }}
                icon="external-link"
                tooltipTitle={t('app.common.showGallery')}
              />
              {type === 'client' && (
                <RoundButton
                  icon="send"
                  tooltipTitle={t('app.access.sendAccess')}
                  onClick={() => {
                    handleOpenEmailModal(
                      EmailTemplateAssociatedModel.GALLERYACCESSCODE,
                      record.contactId ? record.contactId : undefined,
                    );
                  }}
                />
              )}
              {type === 'group' && (
                <Popconfirm
                  title={t('app.access.delete.confirm')}
                  onConfirm={() => {
                    onDelete?.(record);
                  }}
                >
                  <RoundButton icon="delete" tooltipTitle={t('app.access.delete.group')} danger />
                </Popconfirm>
              )}
            </Container>
          ),
        },
        {
          title: type === 'group' ? t('app.common.name') : t('app.common.name') + ' (' + t('app.common.email') + ')',
          key: 'name',
          dataIndex: 'name',
          width: 120,
          render: (_, record) => (
            <StyledSpan>
              {type === 'group' ? record.name : record.contact?.displayName + ' (' + record.contact?.email + ')'}
            </StyledSpan>
          ),
        },
        {
          title: t('app.common.code'),
          key: 'code',
          dataIndex: 'code',
          width: 120,
          render: code => (
            <Container align="center" gap={8}>
              <RoundButton
                icon="copy"
                tooltipTitle={t('app.access.copyAccess')}
                onClick={() => copyToClipboard({ value: code })}
              />
              <StyledSpan>{code}</StyledSpan>
            </Container>
          ),
        },
        type === 'client' && {
          title: t('app.common.order', { count: 2 }) + ' (' + t('app.common.amount') + ')',
          key: 'stats',
          width: 120,
          render: record => (
            <Container align="center" gap={8}>
              <StyledSpan>
                {record.stats.ordersCount} ({record.stats.ordersAmount.toFixed(2)}
                {symbol})
              </StyledSpan>
            </Container>
          ),
        },
      ]),
    [
      copyToClipboard,
      galleryAccessPolicy,
      galleryUrl,
      handleOpenEmailModal,
      onDelete,
      onEdit,
      openModal,
      symbol,
      t,
      type,
    ],
  );
  return (
    <Table
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()} ant-table--bordered`}
      size="middle"
      rowKey="id"
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={{
        total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      onChange={pagination => onChange(pagination)}
    />
  );
};

export default AccessTable;
