import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { EditCatalogForm, EditCatalogFormProps, NewCatalogForm, NewCatalogFormProps } from 'Forms/Catalog';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewPricingModalProps extends NewCatalogFormProps {
  name: 'new';
}
interface EditPricingModalProps extends EditCatalogFormProps {
  name: 'edit';
}

const CatalogModal = (props: NewPricingModalProps | EditPricingModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditCatalogForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
    }

    return <NewCatalogForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
  };

  return (
    <ModalContainer title={t(`app.catalog.modal.${props.name}`)} icon={Images.gallery}>
      {renderForm()}
    </ModalContainer>
  );
};

export default CatalogModal;
