import { gql } from 'Operations/__generated__';

export const GET_GALLERIES = gql(/* GraphQL */ `
  query GetGalleries($where: GalleriesGetWhereType!) {
    getPaginatedGalleries: getGalleries(where: $where) {
      _count
      edges {
        id
        name
        status
        usedSpace
        createdAt
        updatedAt
        slug
        url
        contact {
          id
          firstname
          lastname
          company
          displayName @client
        }
        jobs {
          id
          jobType
          percent
          status
          createdAt
        }
        galleryAppearance {
          id
          coverUrl
          coverUrlLoader
        }
      }
    }
  }
`);
