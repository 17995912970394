import { gql } from 'Operations/__generated__';

export const CREATE_PRODUCT = gql(/* GraphQL */ `
  mutation CreateProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      id
      name
      description
      previewAsset {
        downloadUrl
      }
      weight
      category {
        id
        name
      }
      contactId
      contact {
        id
        firstname
        lastname
        company
        displayName @client
      }
      maxPhotoCount
      orderedByFotostudio
      photoSizes {
        width
        height
      }
    }
  }
`);
