import React, { useCallback, useContext } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { App } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';

import { EditCompanyForm, EditCompanyFormPayload } from 'Forms/Company';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getErrorCode from 'Helpers/GetErrorCode';

import { GET_COMPANY } from 'Operations/Queries/Company/GetCompany';

import { UPDATE_COMPANY } from 'Operations/Mutations/Company/UpdateCompany';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const MyActivityCompanyTab = () => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);

  const { data: companyData } = useQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleEditCompany = useCallback(
    async ({ values, formikBag }: EditCompanyFormPayload) => {
      const { logoUrl: _logoUrl, id: _id, ...data } = values;
      try {
        await updateCompany({ variables: { data: { ...data, primaryColor: data.primaryColor?.toHexString() } } });

        message.success(t('app.message.company.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        console.log(error);

        const errorKey = camelCase(getErrorCode(error as ApolloError));

        if (errorKey === 'uniqConstraintFailed') {
          formikBag.setFieldError('bookingUrl', t(`app.message.error.${errorKey}`, { field: 'url' }));
          message.error(t(`app.message.error.${errorKey}`, { field: 'url' }));
        } else {
          message.error(t('app.message.error.somethingWentWrong'));
        }

        formikBag.setSubmitting(false);
      }
    },
    [updateCompany, message],
  );

  if (!companyData || !companyData?.getCompany) {
    return null;
  }

  const { __typename, settings: _settings, ...company } = companyData?.getCompany;

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      <EditCompanyForm
        onSubmit={handleEditCompany}
        defaultValues={{
          ...company,
          name: company.name || '',
          bookingUrl: company.bookingUrl || '',
          street: company.street || '',
          city: company.city || '',
          zipcode: company.zipcode || '',
          vat: company.vat || '',
          iban: company.iban || '',
          primaryColor: company.primaryColor ? new ColorFactory(company.primaryColor) : undefined,
          mobileMessageSenderId: company.mobileMessageSenderId || '',
        }}
      />
    </StyledTableContentContainer>
  );
};

export default MyActivityCompanyTab;
