import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App, Button, Popconfirm, Spin, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Tag from 'Components/Atoms/Tag';
import Text from 'Components/Atoms/Text';

import { BreadcrumbContent } from 'Components/Molecules/Breadcrumb';
import ClientInfoCard from 'Components/Molecules/ClientInfoCard';
import ContactCard from 'Components/Molecules/ContactCard';
import DeliveryInfoCard from 'Components/Molecules/DeliveryInfoCard';
import OrderItemDrawer from 'Components/Molecules/Drawers/OrderItemDrawer';
import Header from 'Components/Molecules/Header';
import InvoiceInfoCard from 'Components/Molecules/InvoiceInfoCard';
import OrderStatusTag from 'Components/Molecules/OrderStatusTag';
import StatCard from 'Components/Molecules/Stats/StatCard';
import OrderItemTable, { StatusChangeProps } from 'Components/Molecules/Tables/OrderItemTable';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useContactModal } from 'Hooks/useContactModal';
import { useCurrency } from 'Hooks/useCurrency';
import { useOrdersCategoriesMenu } from 'Hooks/useOrdersCategoriesMenu';
import { useRoles } from 'Hooks/useRoles';

import {
  OrderAdmin,
  OrderItem,
  OrderItemHandler,
  OrderItemStatus,
  PaymentMethod,
} from 'Operations/__generated__/graphql';

import { GET_ORDER } from 'Operations/Queries/Order/GetOrder';

import { CANCEL_ORDER } from 'Operations/Mutations/Order/CancelOrder';
import { SET_ORDER_ITEM_STATUS } from 'Operations/Mutations/Order/SetOrderItemStatus';

import OrderPhotos from './Tabs/OrderPhotos';

const { TabPane: TabPaneAnt } = Tabs;

const OrderContainer = styled(Container)`
  flex: 1;
`;
const RowContainer = styled(Container)`
  width: 100%;
  padding: ${Metrics.baseMargin}px 0;
  gap: ${Metrics.baseMargin}px;
`;
const SpinContainer = styled(Container)`
  width: 100%;
  height: 300px;
`;
const StyledTabsContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  padding-top: ${Metrics.smallMargin}px;
`;
const StyledTabs = styled(Tabs)`
  flex: 1;
`;
const TabPane = styled(TabPaneAnt)`
  padding-top: ${Metrics.smallMargin}px;
  padding-bottom: ${Metrics.smallMargin}px;
`;
const StyledInvoiceInfoCard = styled(InvoiceInfoCard)`
  width: 400px;
`;

const SellerCard = styled(ContactCard)`
  width: calc(50% - ${Metrics.smallMargin * 2}px);
  min-width: 400px;
`;

const statCardProps: {
  minWidth: number;
  shadow: boolean;
  width: 'auto';
} = {
  minWidth: 90,
  shadow: true,
  width: 'auto',
};

const OrdersShow = () => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();
  const { message } = App.useApp();
  const { id } = useParams<{ id: string }>();
  const { openModal } = useModals();
  const { isAdmin, isSales, isLabo } = useRoles();
  const orderId = id ? parseInt(id, 10) : undefined;
  useOrdersCategoriesMenu();

  const canManageAllOrders = useMemo(() => {
    return isAdmin || isSales || isLabo;
  }, [isAdmin, isLabo, isSales]);

  const isCrossAccountsEnabled = useMemo(() => {
    return canManageAllOrders && location.pathname.indexOf('/fotostudio') > -1;
  }, [canManageAllOrders]);

  const [activeTabKey, setActiveTabKey] = useState('orderItems');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState<DeepPartial<OrderItem> | null>(null);

  const refetchQueries = [
    {
      query: GET_ORDER,
      variables: { where: { id: orderId } },
    },
  ];

  const { updateContact } = useContactModal({ refetchQueries });

  const {
    data,
    loading: isGetOrderLoading,
    refetch,
  } = useQuery(GET_ORDER, {
    skip: !orderId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: orderId as number,
      },
    },
  });

  const [setOrderItemStatus] = useMutation(SET_ORDER_ITEM_STATUS, {
    refetchQueries,
  });

  const [cancelOrder] = useMutation(CANCEL_ORDER);

  useEffect(() => {
    setIsDrawerOpen(!!selectedOrderItem);
  }, [selectedOrderItem]);

  const handleOrderItemStatusChange = useCallback(
    async ({ id, newStatus }: StatusChangeProps) => {
      const galleryId = data?.getOrder.gallery?.id;
      if (!galleryId) return;
      try {
        await setOrderItemStatus({
          variables: {
            where: {
              orderItemId: id,
              galleryId: galleryId,
            },
            data: { status: newStatus },
          },
        });

        message.success(t('app.message.order.changeOrderItemStatus.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, data?.getOrder.gallery?.id, setOrderItemStatus],
  );

  const getPaymentMethod = (paymentMethod: PaymentMethod | null) => {
    switch (paymentMethod) {
      case PaymentMethod.MOLLIE:
        return 'Mollie';
      case PaymentMethod.STRIPE:
        return 'Stripe';
      case PaymentMethod.OTHER:
        return t('app.settings.payments.other.name');
    }
    return '-';
  };

  const { order, gallery, contactOrder, invoice, isOnlyDigital } = useMemo(() => {
    const order = data?.getOrder as OrderAdmin;
    const gallery = order?.gallery;
    return {
      order,
      gallery,
      contactOrder: order?.contact || gallery?.contact,
      invoice: order?.invoice,
      isHandledByFotostudio: order?.orderedByFotostudio === true,
      isOnlyDigital: order?.items?.every(item => item.product?.category.isDigital) || false,
    };
  }, [data?.getOrder]);

  const headerButtons = useMemo(() => {
    const buttons = [];

    if (gallery && order && order.status !== OrderItemStatus.CANCELLED) {
      buttons.push(
        <Button
          key="download"
          size="large"
          type="primary"
          onClick={() => openModal('DOWNLOAD_PHOTOS', { orderId: order.id })}
        >
          <Icon name="download" /> {t('app.order.downloadPhotos')}
        </Button>,
      );

      buttons.push(
        <Popconfirm
          key="cancel"
          title={t(`app.order.cancel.confirm`)}
          // @ts-ignore
          onClick={e => e.stopPropagation()}
          onConfirm={() => cancelOrder({ variables: { where: { galleryId: gallery.id, orderId: order.id } } })}
          placement="bottomLeft"
        >
          <Button size="large">
            <Icon name="disable" /> {t('app.order.cancel.button')}
          </Button>
        </Popconfirm>,
      );
    }

    return buttons;
  }, [cancelOrder, gallery, openModal, order, t]);

  const breadcrumb = useMemo(() => {
    const bc: BreadcrumbContent[] = [];

    if (!isLabo) {
      bc.push({ text: t('app.menu.home'), url: '/app/dashboard' });
    }
    bc.push(
      {
        text: t('app.common.order', { count: 2 }),
        url: isCrossAccountsEnabled ? '/app/orders/fotostudio' : '/app/orders',
      },
      { text: order ? `#${order.id.toString().padStart(6, '0')}` : t('app.common.order', { count: 1 }) },
    );

    return bc;
  }, [isCrossAccountsEnabled, isLabo, order, t]);

  const isPhotographerMustReceiveOrder = useMemo(
    () => order?.photographerMustReceiveOrder && order?.orderedByFotostudio,
    [order?.orderedByFotostudio, order?.photographerMustReceiveOrder],
  );

  return (
    <>
      <Header title={t('app.common.order', { count: 2 })} breadcrumbContent={breadcrumb} buttons={headerButtons} />
      <Layout>
        {isGetOrderLoading && !order && (
          <SpinContainer justify="center" align="center">
            <Spin size="large" />
          </SpinContainer>
        )}
        {order && (
          <OrderContainer direction="column">
            <RowContainer align="space-around">
              {isCrossAccountsEnabled && gallery && (
                <StatCard {...statCardProps} label={t('app.orders.seller')} value={order.seller?.companyName || '-'} />
              )}
              {gallery && (
                <StatCard
                  {...statCardProps}
                  label={t('app.common.gallery')}
                  value={gallery.name || '-'}
                  href={isCrossAccountsEnabled === false && gallery ? `/app/galleries/${gallery.id}` : undefined}
                />
              )}
              {contactOrder && (
                <StatCard
                  {...statCardProps}
                  label={t('app.common.contact')}
                  value={contactOrder ? contactOrder.displayName : '-'}
                  onClick={contactOrder ? () => updateContact(contactOrder.id) : undefined}
                />
              )}
              {invoice && (
                <StatCard
                  {...statCardProps}
                  label={t('app.common.amount')}
                  value={<Text weight="bold">{`${formatCurrency(invoice.ttcPrice)}`}</Text>}
                />
              )}
              <StatCard
                {...statCardProps}
                label={t('app.common.paymentStatus')}
                value={
                  <Tag color={order.isPaid ? Colors.success : Colors.waiting} size="small">
                    {t(order.isPaid ? 'app.common.paid' : 'app.common.waitingForPayment')}
                  </Tag>
                }
              />
              <StatCard
                {...statCardProps}
                label={t('app.common.paymentMethod')}
                value={getPaymentMethod(order.paymentMethod || null)}
              />
              <StatCard
                {...statCardProps}
                label={t('app.orders.handledBy')}
                value={
                  <Container direction="row" gap={Metrics.tinyMargin}>
                    {order.handlers.map(handler => (
                      <Tag
                        key={handler}
                        color={handler === OrderItemHandler.INCOGNITO ? Colors.crmMain : Colors.black}
                        size="small"
                      >
                        {handler === OrderItemHandler.INCOGNITO
                          ? 'Fotostudio'
                          : t(`app.common.${handler.toLowerCase()}`)}
                      </Tag>
                    ))}
                  </Container>
                }
              />
              <StatCard
                {...statCardProps}
                label={t('app.common.status')}
                value={<OrderStatusTag itemStatus={order.status} />}
              />
              <DeliveryInfoCard
                receiver={
                  isPhotographerMustReceiveOrder
                    ? order?.seller?.companyName || '-'
                    : `${order.firstName} ${order.lastName}`
                }
                address={
                  isPhotographerMustReceiveOrder
                    ? order.seller?.companyAddress || 'Missing seller'
                    : `${order.street}, ${order.zipCode} ${order.city} ${order.country}`
                }
              />
              {isPhotographerMustReceiveOrder && <ClientInfoCard {...order} />}
            </RowContainer>
            <StyledTabsContainer shadow rounded padding="baseMargin">
              {data?.getOrder.items.some(({ hasCroppedPhotos }) => hasCroppedPhotos) && (
                <Alert message={t('app.order.hasCroppedPhotos')} />
              )}
              {data?.getOrder.items.some(({ status, price }) => status === OrderItemStatus.CANCELLED && price > 0) && (
                <Alert type="warning" message={t('app.order.hasCancelledItems')} />
              )}
              <StyledTabs activeKey={activeTabKey} onChange={newTabKey => setActiveTabKey(newTabKey)}>
                <TabPane tab={t('app.common.orderItem', { count: order.items.length })} key={'orderItems'}>
                  <OrderItemTable
                    order={order}
                    isCrossAccountsEnabled={isCrossAccountsEnabled}
                    handleOnView={({ id }) => {
                      const orderItem = order.items.find(x => x.id === id);
                      if (!orderItem || !gallery?.id) {
                        console.error('Could not find orderItem');
                        return;
                      }

                      setSelectedOrderItem({
                        id: orderItem.id,
                        price: orderItem.price,
                        packageUrl: orderItem.packageUrl ?? undefined,
                        hasCroppedPhotos: orderItem.hasCroppedPhotos,
                        product: {
                          ...orderItem.product,
                          name: orderItem.productName || orderItem.product?.name || '-',
                          description: 'orderItem.',
                        },
                        options: orderItem.options,
                        quantity: orderItem.quantity,
                      });
                    }}
                    handleOnStatusChange={handleOrderItemStatusChange}
                  />
                </TabPane>
                {!isOnlyDigital && (
                  <TabPane tab={t('app.common.photo', { count: 2 })} key="photos">
                    <OrderPhotos order={order} />
                  </TabPane>
                )}
                {invoice?.url && (
                  <TabPane tab={t('app.common.invoices', { count: 1 })} key={'invoice'}>
                    <StyledInvoiceInfoCard
                      id={invoice.id}
                      createdAt={invoice.createdAt}
                      numero={invoice.numero || ''}
                      isPaid={invoice.paid}
                      title={invoice.title || ''}
                      ttcPrice={invoice.ttcPrice}
                      url={invoice.url}
                      onInvoicePaid={refetch}
                    />
                  </TabPane>
                )}
                {isCrossAccountsEnabled && order.seller && (
                  <TabPane tab={t('app.orders.seller')} key={'seller'}>
                    <SellerCard
                      company={order.seller.companyName}
                      displayName={order.seller.companyName}
                      email={order.seller.email}
                      address={order.seller.companyAddress}
                    />
                  </TabPane>
                )}
              </StyledTabs>
            </StyledTabsContainer>
          </OrderContainer>
        )}
      </Layout>
      {order && gallery?.id && (
        <OrderItemDrawer
          galleryId={gallery.id}
          workmode={gallery.workmode}
          isVisible={isDrawerOpen}
          onCloseDrawer={() => {
            setSelectedOrderItem(null);
          }}
          orderItem={selectedOrderItem || undefined}
        />
      )}
    </>
  );
};

export default OrdersShow;
