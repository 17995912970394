import React from 'react';

import { FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

export interface TitleProps {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  align?: 'left' | 'center' | 'right';
  spaced?: boolean;
  color?: string;
  children: React.ReactNode;
}

const StyledTitle = styled.h1<TitleProps>`
  font-family: 'Fira Sans', sans-serif;
  font-weight: 800;
  font-size: ${({ level }: TitleProps) => FontSize[level]}px;
  color: ${({ color = '#000' }) => color};
  text-align: ${({ align }: TitleProps) => align};
  margin-bottom: ${({ spaced = false }) => (spaced ? Metrics.smallMargin : 0)}px;
`;

const Title = ({ level, align = 'left', children, color, ...props }: TitleProps) => {
  return (
    <StyledTitle as={level} level={level} align={align} color={color} {...props}>
      {children}
    </StyledTitle>
  );
};

export default Title;
