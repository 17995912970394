import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY = gql(/* GraphQL */ `
  mutation UpdateGallery($where: GalleryUpdateWhereType!, $data: GalleryUpdateInputType!) {
    updateGallery(where: $where, data: $data) {
      id
      status
      ... on GalleryAdmin {
        photosOrder
        watermarkId
        watermarkMode
        photosCustomOrder
        isClientCodesAdminEnabled
      }
      includedProducts {
        id
        accessRole
        quantity
        hasAllIncluded
        product {
          id
          name
          category {
            isDigital
          }
        }
      }
      mandatoryProduct {
        id
      }
    }
  }
`);
