import React, { useCallback, useContext } from 'react';

import { Card } from 'antd';

import Container from 'Components/Atoms/Container';
import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useCurrency } from 'Hooks/useCurrency';

import dayjs from 'Services/DayjsService';

import InfoItem from './InfoItem';

const SpacedRoundButton = styled(RoundButton)`
  margin-left: ${Metrics.tinyMargin}px;
`;

const MyTag = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;

const MySpan = styled.span<{ isPaid: boolean }>`
  color: ${({ isPaid }) => (isPaid ? Colors.success : Colors.waiting)};
`;

interface InvoiceInfoCardProps {
  id: number;
  url: string;
  ttcPrice: number;
  createdAt: string;
  isPaid: boolean;
  title: string;
  numero: string;
  onInvoicePaid: () => void;
}

const { Meta } = Card;

const InvoiceInfoCard = ({
  id,
  url,
  ttcPrice,
  createdAt,
  isPaid,
  title,
  numero,
  onInvoicePaid,
}: InvoiceInfoCardProps) => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();
  const { openModal } = useModals();

  const handleOpenPaymentModal = useCallback(
    ({ invoiceId, invoiceAmount }: { invoiceId: number; invoiceAmount: number }) => {
      openModal('PAYMENT', {
        invoiceId,
        invoiceAmount,
        onSubmit: onInvoicePaid,
      });
    },
    [onInvoicePaid, openModal],
  );

  return (
    <Card bordered={false}>
      <Meta
        title={
          <Container justify="flex-start" align="center">
            <p className="ant-card-meta-title">#{numero}</p>
            <MyTag color={Colors.grey} size="medium">
              {t('app.common.invoices', { count: 1 })}
            </MyTag>
          </Container>
        }
        description={
          <>
            <InfoItem label={t('app.common.title')} text={title} />
            <InfoItem label={t('app.common.createdAt')} text={dayjs(createdAt).format('LLL')} />
            <InfoItem label={t('app.common.price')} text={`${formatCurrency(ttcPrice)}`} />
            <InfoItem
              label={t('app.common.paymentStatus')}
              text={<MySpan isPaid={isPaid}>{t(isPaid ? 'app.common.paid' : 'app.common.waitingForPayment')}</MySpan>}
            />
            <br />
            <Container justify="flex-end">
              <a href={url} target="_blank">
                <SpacedRoundButton icon="download" tooltipTitle={t('app.common.download')} />
              </a>
              <SpacedRoundButton
                icon="add"
                tooltipTitle={t('app.invoices.createPayment')}
                onClick={() => {
                  handleOpenPaymentModal({ invoiceId: id, invoiceAmount: ttcPrice });
                }}
              />
            </Container>
          </>
        }
      />
    </Card>
  );
};

export default InvoiceInfoCard;
