import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { SendEmailForm, SendEmailFormProps } from 'Forms/SendEmail';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

const StyledTitle = styled(Title)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${Metrics.doubleBaseMargin}px 0 ${Metrics.mediumMargin}px 0;
  font-style: italic;
  color: ${Colors.white};
`;
const StyledIcon = styled(Icon)`
  margin-right: ${Metrics.tinyMargin}px;
`;

const StyledText = styled(Text)`
  margin-bottom: ${Metrics.mediumMargin}px;
  color: ${Colors.white};
`;

const EmailTemplateModal = (props: SendEmailFormProps) => {
  const { t } = useContext(LocalizationContext);
  const { closeModal } = useModals();

  return (
    <ModalContainer
      title={t('app.gallery.send.title')}
      icon={Images.email}
      subtitle={
        <>
          <StyledTitle level="h5">
            <StyledIcon color={Colors.white} name="tips" size={22} />
            <span>{t('app.gallery.send.subtitle')}</span>
          </StyledTitle>
          <ReactMarkdown
            children={t('app.gallery.send.content', {
              link: '/app/settings/email-config',
            })}
            components={{
              p: ({ children }) => <StyledText size="medium">{children}</StyledText>,
              a: ({ children, ...props }) => (
                <Link
                  to={props.href || '.'}
                  style={{
                    color: Colors.white,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {children}
                </Link>
              ),
            }}
          />
        </>
      }
    >
      <SendEmailForm {...props} />
    </ModalContainer>
  );
};

export default EmailTemplateModal;
