import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import ContactTable, { handleOnChangeProps } from 'Components/Molecules/Tables/ContactTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useContactModal } from 'Hooks/useContactModal';

import { OrderType } from 'Operations/__generated__/graphql';

import { GET_PAGINATED_CONTACTS } from 'Operations/Queries/Contact/GetPaginatedContacts';

import { DELETE_CONTACT } from 'Operations/Mutations/Contact/DeleteContact';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 20;

const ContactsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const { message } = App.useApp();

  const [queryParams, setQueryParams] = useState<{
    page: number;
    order: OrderType;
    lastname?: string;
    perPage: number;
  }>({
    page: 1,
    order: OrderType.DESC,
    perPage: PER_PAGE,
  });

  const refetchQueries = useMemo(
    () => [
      {
        query: GET_PAGINATED_CONTACTS,
        variables: {
          where: queryParams,
        },
      },
    ],
    [queryParams],
  );

  const { createContact, updateContact } = useContactModal({ refetchQueries });

  const {
    data,
    loading: isContactsLoading,
    fetchMore,
    refetch,
  } = useQuery(GET_PAGINATED_CONTACTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: queryParams,
    },
  });

  const currentPage = useMemo(
    () => Math.floor((data?.getPaginatedContacts.edges.length || 0) / PER_PAGE),
    [data?.getPaginatedContacts.edges.length],
  );

  const [deleteContact] = useMutation(DELETE_CONTACT, {
    refetchQueries,
    awaitRefetchQueries: true,
    update(cache, { data }) {
      if (data) {
        const normalizedId = cache.identify({ id: data.deleteContact.id, __typename: 'Contact' });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  const handleFilterTable = useCallback(
    async ({ order, lastname, page: newPage }: handleOnChangeProps) => {
      const params = {
        ...queryParams,
        order,
        lastname,
      };

      if (newPage !== currentPage) {
        params.page = newPage;
        await fetchMore?.({
          variables: {
            where: params,
          },
        });
      } else {
        // Reset pagination to show new results
        params.page = 1;
        await refetch?.({
          where: params,
        });
      }

      // Update variables for the original useQuery to pass them to the read function in the cache policies
      setQueryParams(params);
    },
    [currentPage, fetchMore, queryParams, refetch],
  );

  const handleDeleteConfirm = useCallback(
    async ({ id }: { id: number }) => {
      try {
        await deleteContact({
          variables: {
            where: {
              id,
            },
          },
        });

        message.success(t('app.message.contact.delete.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [deleteContact, t],
  );

  const goToTransportFees = useCallback(
    ({ id }: { id: number }) => {
      navigate(`/app/contacts/${id}/transport-fees`);
    },
    [navigate],
  );

  return (
    <>
      <Header
        title={t('app.contact.manage')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.common.contacts') }]}
        buttons={[
          <Button key="add" type="primary" size="large" onClick={createContact}>
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <StyledTableContentContainer>
          <ContactTable
            contactsTotal={data?.getPaginatedContacts._count}
            data={data?.getPaginatedContacts.edges}
            paginationSize={PER_PAGE}
            loading={isContactsLoading}
            handleOnChange={handleFilterTable}
            handleEditClick={({ id }) => updateContact(id)}
            handleEditTransportFees={goToTransportFees}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default ContactsIndex;
