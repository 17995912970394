import { gql } from 'Operations/__generated__';

export const CREATE_ACCESS_CODE = gql(/* GraphQL */ `
  mutation CreateAccessCode($where: IdInput!, $data: AccessCodeCreateDataType!) {
    createAccessCode(where: $where, data: $data) {
      code
      createdAt
      stats {
        ordersAmount
        ordersCount
      }
      contactId
      galleryId
      contact {
        firstname
        lastname
        email
        displayName @client
      }
      hasFullAccess
      name
      __typename
      accessRight {
        id
        __typename
      }
    }
  }
`);
