import React, { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import Icon from 'Components/Atoms/Icon';

import i18n, { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface VideoGalleryFormValues {
  video?: string | null;
}
export interface VideoGalleryFormPayload {
  values: VideoGalleryFormValues;
  formikBag: FormikBag<VideoGalleryFormProps, VideoGalleryFormValues>;
}
export interface VideoGalleryFormProps {
  onSubmit: (payload: VideoGalleryFormPayload) => void;
  defaultValues: VideoGalleryFormValues;
}

const VideoForm = <T extends VideoGalleryFormValues>({ isSubmitting }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Form layout="vertical">
      <Flex gap="middle" align="center">
        <Form.Item label={t('app.gallery.video.label')} name="video" hasFeedback={false}>
          <Input name="video" placeholder={t('app.gallery.video.placeholder')} size="large" />
        </Form.Item>
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          <Flex>
            <Icon name={'edit'} size={20} />
            {t('app.common.save')}
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

const videoGallerySchema: yup.SchemaOf<VideoGalleryFormValues> = yup
  .object({
    video: yup
      .string()
      .nullable()
      .test('is-video-url-valid', i18n.t('app.gallery.wrongVideoLink'), value => {
        if (value) {
          return /youtube\.com\/|youtu\.be\/|vimeo\.com\//.test(value);
        }
        return true;
      }),
  })
  .defined();
export const UpdateVideoForm = withFormik<VideoGalleryFormProps, VideoGalleryFormValues>({
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    enableIncludedProduct: true,
  }),
  validationSchema: videoGallerySchema,
})(VideoForm);
