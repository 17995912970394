import { gql } from 'Operations/__generated__';

export const CREATE_GALLERY_PRESET = gql(/* GraphQL */ `
  mutation CreateGalleryPreset($data: GalleryCreatePresetCreateInput!) {
    createGalleryPreset(data: $data) {
      id
      name
      accessPolicy
      watermarkId
      locale
      watermarkMode
      watermarkId
      workmode
      createdAt
      catalog {
        id
        name
        isDefault
      }
      galleryAppearance {
        ...GalleryAppearanceFields
      }
    }
  }
`);
