import { ChangeEvent, useContext, useState } from 'react';

import { App, Button, Flex, Popconfirm } from 'antd';
import { ATTACHMENT_TYPE, AttachmentInfo } from 'types/Attachment';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import EmailEditor from 'Components/Molecules/EmailEditor';
import FileSelect from 'Components/Molecules/Form/FileSelect';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import { getApolloClient } from 'Providers/ApolloProvider';

import yup from 'Services/YupService';

import { toAttachmentType } from 'Helpers/AttachmentType';

import { EmailTemplateAssociatedModel } from 'Operations/__generated__/graphql';

import { DELETE_EMAIL_TEMPLATE_ATTACHMENT } from 'Operations/Mutations/EmailTemplate/DeleteEmailTemplateAttachment';

export interface NewEmailTemplateFormValues {
  name: string;
  title: string;
  content: string;
  attachment?: Blob | null;
  attachmentName?: string | null;
  attachmentUrl?: string | null;
}

export interface EditEmailTemplateFormValues extends NewEmailTemplateFormValues {
  id: number;
}

const DeleteAttachment = styled(RoundButton)`
  margin-left: ${Metrics.smallMargin}px;
`;

const AttachmentContainer = styled(Flex)`
  margin-bottom: ${Metrics.tinyMargin}px;
`;

const AttachmentPreview = styled.img`
  width: 100px;
  height: auto;
  margin-right: ${Metrics.baseMargin}px;
`;

const TextStyled = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    display: flex;
    align-items: center;
    color: ${Colors.secondaryMain};
  }
`;

const EmailTemplateForm = <T extends NewEmailTemplateFormValues | EditEmailTemplateFormValues>({
  isSubmitting,
  values,
  setFieldValue,
  associatedModel,
}: FormikProps<T> & EditTemplateProps) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const [attachmentInfo, setAttachmentInfo] = useState<AttachmentInfo>({
    type: toAttachmentType(values.attachmentName),
    name: values.attachmentName ?? undefined,
    url: values.attachmentUrl ?? undefined,
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const selectedFile = e.target.files[0];
    setAttachmentInfo({
      type: toAttachmentType(selectedFile.type),
      name: undefined, // Prevents display of file name, which is already done by the FileSelect component
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleRemoveAttachment = async () => {
    try {
      if ((values as EditEmailTemplateFormValues).id) {
        const apolloClient = getApolloClient();

        await apolloClient.mutate({
          mutation: DELETE_EMAIL_TEMPLATE_ATTACHMENT,
          variables: {
            where: {
              id: (values as EditEmailTemplateFormValues).id,
            },
          },
          update(cache) {
            const normalizedId = cache.identify({
              id: (values as EditEmailTemplateFormValues).id,
              __typename: 'EmailTemplate',
            });
            cache.modify({
              id: normalizedId,
              fields: {
                attachmentName() {
                  return null;
                },
                attachmentUrl() {
                  return null;
                },
              },
            });
          },
        });

        setFieldValue('attachmentUrl', undefined);
        setFieldValue('attachmentName', undefined);

        setAttachmentInfo({
          type: undefined,
          name: undefined,
          url: undefined,
        });

        message.success(t('app.emailTemplate.attachment.delete.success'));
      }
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
        <Input name="name" placeholder={t('app.common.name')} size="large" />
      </Form.Item>
      <Form.Item label={t('app.common.title')} name="title" required hasFeedback={false}>
        <Input name="title" placeholder={t('app.common.title')} size="large" />
      </Form.Item>
      <Form.Item label={t('app.common.content')} name="content" required hasFeedback={false}>
        <EmailEditor
          model={values.content}
          onModelChange={(content: string) => setFieldValue('content', content)}
          associatedModel={associatedModel}
        />
        <Input type="hidden" name="content" />
      </Form.Item>
      <Form.Item label={t('app.common.attachment')} name="attachment" hasFeedback={false}>
        <AttachmentContainer align="center">
          {attachmentInfo.type === ATTACHMENT_TYPE.IMAGE && <AttachmentPreview src={attachmentInfo.url} />}
          {attachmentInfo.type === ATTACHMENT_TYPE.PDF && <Icon name="pdf" size={FontSize.h1 * 2} />}
          {attachmentInfo.type === ATTACHMENT_TYPE.FILE && <Icon name="file" size={FontSize.h1 * 2} />}
          {attachmentInfo.name && attachmentInfo.url && (
            <>
              <TextStyled size="small">
                <a href={attachmentInfo.url} target="_blank">
                  <Icon name="invoice" />
                  {attachmentInfo.name}
                </a>
              </TextStyled>
              <Popconfirm title={t('app.confirm.delete')} onConfirm={handleRemoveAttachment}>
                <DeleteAttachment icon="delete" tooltipTitle={t('app.common.delete')} danger />
              </Popconfirm>
            </>
          )}
        </AttachmentContainer>
        <FileSelect
          name="attachment"
          accept="image/jpeg, image/png, image/gif,
          application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-ole-storage,
          application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-office, application/vnd.openxmlformats-officedocument.presentationml.presentation,
          application/rtf, application/x-rtf, text/rtf, text/richtext, application/zip,
          application/pdf"
          handleOnChange={handleFileChange}
        />
      </Form.Item>
      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" size="large" loading={isSubmitting}>
          {t((values as EditEmailTemplateFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface EditTemplateProps {
  associatedModel: EmailTemplateAssociatedModel;
}

export interface NewEmailTemplatePayload {
  values: NewEmailTemplateFormValues;
  formikBag: FormikBag<NewEmailTemplateFormProps, NewEmailTemplateFormValues>;
}

export interface EditEmailTemplatePayload {
  values: EditEmailTemplateFormValues;
  formikBag: FormikBag<EditEmailTemplateFormProps, EditEmailTemplateFormValues>;
}

export interface NewEmailTemplateFormProps extends EditTemplateProps {
  onSubmit: (payload: NewEmailTemplatePayload) => void;
}

export interface EditEmailTemplateFormProps extends EditTemplateProps {
  onSubmit: (payload: EditEmailTemplatePayload) => void;
  defaultValues: EditEmailTemplateFormValues;
}

const emailTemplateSchema = yup.object().shape({
  name: yup.string().trim().required(),
  title: yup.string().trim().required(),
  content: yup.string().trim().required(),
  attachment: yup
    .mixed()
    .nullable()
    .test(
      'attachment',
      () => i18n.t('app.form.errors.fileToLarge', { limit: '5MB' }),
      value => {
        return value ? value.size <= 5000000 : true;
      },
    ),
});

const newEmailTemplateSchema: yup.SchemaOf<NewEmailTemplateFormValues> = emailTemplateSchema.defined();
const editEmailTemplateSchema: yup.SchemaOf<EditEmailTemplateFormValues> = emailTemplateSchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewEmailTemplateForm = withFormik<NewEmailTemplateFormProps, NewEmailTemplateFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: () => ({
    name: '',
    title: '',
    content: '',
    attachment: undefined,
    attachmentName: undefined,
    attachmentUrl: undefined,
  }),
  validationSchema: newEmailTemplateSchema,
})(EmailTemplateForm);

export const EditEmailTemplateForm = withFormik<EditEmailTemplateFormProps, EditEmailTemplateFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editEmailTemplateSchema,
})(EmailTemplateForm);
