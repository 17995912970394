import { gql } from 'Operations/__generated__';

export const GET_GALLERY = gql(/* GraphQL */ `
  query GetGallery($where: GalleryGetWhereType!) {
    getGallery(where: $where) {
      ...GalleryCoreFields
      ... on GalleryAdmin {
        watermarkId
        watermarkMode
        isClientCodesAdminEnabled
        photosOrder
        photosCustomOrder
        accessPolicy
        accessCode
        slug
        video
        secondaryContacts {
          ...ContactCoreFields
        }
        currency {
          symbol
        }
        _count {
          photosViewCount
        }
        jobs {
          id
          jobType
          percent
          status
          createdAt
        }
        includedProducts {
          id
          accessRole
          quantity
          hasAllIncluded
          product {
            id
            name
            category {
              isDigital
            }
          }
        }
        mandatoryProduct {
          id
        }
      }
    }
  }
`);
