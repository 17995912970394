import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App } from 'antd';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { NewOptionCategoryForm, NewOptionCategoryFormPayload, NewOptionCategoryFormProps } from 'Forms/OptionCategory';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import { GET_PRODUCT } from 'Operations/Queries/Product/GetProduct';

import { CREATE_PRODUCT_OPTION_CATEGORY } from 'Operations/Mutations/ProductOptionCategory/CreateProductOptionCategory';

const OptionCategoryModal = (props: NewOptionCategoryFormProps) => {
  const { t } = useContext(LocalizationContext);
  const { closeModal } = useModals();
  const { message } = App.useApp();

  const [createProductOptionCategory] = useMutation(CREATE_PRODUCT_OPTION_CATEGORY, {
    refetchQueries: [
      {
        query: GET_PRODUCT,
        variables: {
          where: { id: props.product.id },
        },
      },
    ],
  });

  const handleSubmit = useCallback(
    async ({ values, formikBag }: NewOptionCategoryFormPayload) => {
      try {
        if (!props?.product.id) {
          message.error(t(`app.message.error.somethingWentWrong`));
          return;
        }

        await createProductOptionCategory({
          variables: {
            where: {
              id: props.product.id,
            },
            data: values,
          },
        });

        message.success(t('app.message.optionCategory.create.success'));
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);

        message.error(errorMessage);
      } finally {
        formikBag.setSubmitting(false);
        closeModal();
      }
    },
    [closeModal, createProductOptionCategory, props.product.id, t],
  );

  return (
    <ModalContainer title={t(`app.addProductCategory.modal.new`)} icon={Images.tag}>
      <NewOptionCategoryForm onSubmit={handleSubmit} defaultValues={props.defaultValues} product={props.product} />
    </ModalContainer>
  );
};

export default OptionCategoryModal;
