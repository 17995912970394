import { gql } from 'Operations/__generated__';

export const GET_ORDERS = gql(/* GraphQL */ `
  query GetOrders($where: GetOrdersWhereInput!) {
    getOrders(where: $where) {
      _count
      edges {
        id
        isValidated
        invoiceId
        isPaid
        items {
          id
          quantity
          requestQuantity
          productName
          status
          photos(where: { page: 1, perPage: 50 }) {
            _count
            edges {
              ... on PhotoAdmin {
                id
                name
                isRetouched
                isLiked
                folder {
                  id
                }
                asset {
                  assetMain {
                    key
                    type
                    size
                    uploadStatus
                    downloadUrl
                  }
                  assetWeb {
                    key
                    type
                    size
                    uploadStatus
                    downloadUrl
                  }
                  noWmAssetWeb {
                    downloadUrl
                  }
                  thumbSmall {
                    downloadUrl
                  }
                  thumbLarge {
                    downloadUrl
                  }
                  noWmThumbSmall {
                    downloadUrl
                  }
                  noWmThumbLarge {
                    downloadUrl
                  }
                }
              }
            }
          }
        }
        ... on OrderAdmin {
          handlers
          contact {
            id
            firstname
            lastname
            company
            displayName @client
            email
          }
        }
      }
    }
  }
`);
