import { gql } from 'Operations/__generated__';

export const GET_PAGINATED_CONTACTS = gql(/* GraphQL */ `
  query GetPaginatedContacts($where: ContactsGetInputType!) {
    getPaginatedContacts: getContacts(where: $where) {
      _count
      edges {
        ...ContactCoreFields
      }
    }
  }
`);
