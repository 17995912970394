import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Alert, Button, Flex } from 'antd';

import clsx from 'clsx';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { DatePicker, Form, Input, InputNumber, Switch } from 'formik-antd';
import { camelCase, chain, compact, flatten, kebabCase, uniqBy } from 'lodash';

import Fieldset from 'Components/Atoms/Fieldset';
import Icon from 'Components/Atoms/Icon';
import Legend from 'Components/Atoms/Legend';
import Text from 'Components/Atoms/Text';

import Select from 'Components/Molecules/Form/Select';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import generateId from 'Helpers/GenerateId';

import {
  GalleryAccessPolicy,
  GalleryAdmin,
  GalleryDownload,
  GalleryStatus,
  GalleryWorkmode,
  GetCatalogsQuery,
  GetGalleryPresetsQuery,
  Locale,
  OrderType,
} from 'Operations/__generated__/graphql';

import { GET_CATALOG } from 'Operations/Queries/Catalog/GetCatalog';
import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';
import { GET_COMPANY_SETTINGS } from 'Operations/Queries/Company/GetCompanySettings';
import { GET_CONTACTS } from 'Operations/Queries/Contact/GetContacts';
import { GET_GALLERY_CONTACTS } from 'Operations/Queries/Gallery/GetGalleryContacts';
import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';
import { GET_GALLERY_PRESETS } from 'Operations/Queries/GalleryPreset/GetGalleryPresets';
import { GET_DIGITAL_PRODUCTS } from 'Operations/Queries/Product/GetDigitalProducts';
import { GET_PROJECTS } from 'Operations/Queries/Project/GetProjects';
import { ME } from 'Operations/Queries/User/Me';

export interface NewGalleryFormValues {
  projectId?: number | null;
  galleryPresetId?: number | null;
  name: string;
  url?: string | null;
  video?: string | null;
  contactId?: number | null;
  secondaryContactIds?: number[] | null;
  status: GalleryStatus;
  availableAt?: string | null;
  expiredAt?: string | null;
  shootedAt?: string | null;
  accessCode?: string;
  inPortfolio: boolean;
  isShopEnable: boolean;
  catalogId?: number | null;
  workmode?: GalleryWorkmode | null;
  accessPolicy: GalleryAccessPolicy;
  enableIncludedProduct: boolean;
  // Included Products Properties
  includedProductId?: number | null;
  hasAllPhotosIncluded: boolean;
  includedPhotosCount?: number | null;
  includedProductIdForGuest?: number | null;
  hasAllPhotosIncludedForGuest: boolean;
  // Mandatory Product Property
  enableMandatoryProduct: boolean;
  mandatoryProductId?: number | null;
}

export interface EditGalleryFormValues extends NewGalleryFormValues {
  id: number;
}

interface GalleryFormProps {
  minIncludedCount?: number;
}

interface DataQueryParams {
  perPage: number;
  order: OrderType;
  page: number;
  search?: string;
}

const PER_PAGE = 20;
const EDGES_PARAMS = {
  perPage: PER_PAGE,
  order: OrderType.ASC,
  page: 1,
};
const DIGITAL_PRODUCTS_PARAMS = {
  perPage: PER_PAGE,
};

const WarningText = styled(Text)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const FieldsetStyled = styled(Fieldset)`
  width: 100%;
`;

const MandatoryFormItemStyled = styled(Form.Item)`
  max-width: 454px;
`;

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const GalleryForm = <T extends EditGalleryFormValues | NewGalleryFormValues>({
  isSubmitting,
  values,
  errors,
  initialValues,
  setValues,
  setFieldValue,
  setFieldTouched,
  getFieldMeta,
  getFieldProps,
  minIncludedCount = 0,
}: FormikProps<T> & GalleryFormProps) => {
  const { t } = useContext(LocalizationContext);
  const digitalProductsPage = useRef(1);

  const { onChange: onAccessTypeChanged } = getFieldProps('accessType');

  const [isAccessCodeInputAvailable, setIsAccessCodeInputVisible] = useState<boolean>(
    values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE,
  );

  const [contactsQueryParams, setContactsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [projectsQueryParams, setProjectsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [catalogsQueryParams, setCatalogsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [presetsQueryParams, setPresetsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);

  const galleryId = (values as EditGalleryFormValues).id;
  const catalogId = (values as EditGalleryFormValues).catalogId;

  const { data: meData } = useQuery(ME);

  const hasCRM = !!meData?.me?.hasCRM;

  // Fetch slected gallery contacts to show them even if not in the current pagination
  const { data: galleryContactsData } = useQuery(GET_GALLERY_CONTACTS, {
    fetchPolicy: 'cache-first',
    skip: !galleryId,
    variables: {
      where: {
        id: galleryId,
      },
    },
  });

  const {
    data: contactsData,
    loading: isContactsLoading,
    fetchMore: fetchMoreContacts,
  } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: contactsQueryParams,
    },
  });

  const {
    data: presetsData,
    loading: isPresetsLoading,
    fetchMore: fetchMorePresets,
  } = useQuery(GET_GALLERY_PRESETS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: presetsQueryParams,
    },
  });

  const [getPreset, { data: galleryPresetData }] = useLazyQuery(GET_GALLERY_PRESET);

  const {
    data: projectsData,
    loading: isProjectsLoading,
    fetchMore: fetchMoreProjects,
  } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    skip: !hasCRM,
    variables: {
      where: projectsQueryParams,
    },
  });

  const {
    data: catalogsData,
    loading: isCatalogsLoading,
    fetchMore: fetchMoreCatalogs,
  } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: catalogsQueryParams,
    },
  });

  useQuery(GET_CATALOG, {
    skip: !catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: catalogId as number,
      },
    },
  });

  const { data: userSettingsData } = useQuery(GET_COMPANY_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  const {
    data: digitalProductsData,
    loading: isProductsLoading,
    fetchMore: fetchMoreProducts,
  } = useQuery(GET_DIGITAL_PRODUCTS, {
    skip: !values.catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        ...DIGITAL_PRODUCTS_PARAMS,
        page: digitalProductsPage.current,
        catalogId: values.catalogId,
      },
    },
  });

  const catalogs: {
    _count: number;
    edges: GetCatalogsQuery['getCatalogs']['edges'];
  } = useMemo(() => {
    if (!catalogsData?.getCatalogs) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...catalogsData.getCatalogs.edges], 'id'),
      _count: catalogsData.getCatalogs._count,
    };
  }, [catalogsData?.getCatalogs]);

  const galleryContacts = useMemo(
    () => galleryContactsData?.getGallery as GalleryAdmin,
    [galleryContactsData?.getGallery],
  );

  // Merge the paginated contacts and the gallery contacts
  const contacts = useMemo(() => {
    const mergedContacts = [...(contactsData?.getContacts.edges || [])];
    if (galleryContacts) {
      if (galleryContacts.contact) {
        mergedContacts.push(galleryContacts.contact);
      }
      if (galleryContacts.secondaryContacts?.length) {
        mergedContacts.push(...galleryContacts.secondaryContacts);
      }
    }

    if (projectsData?.getProjects.edges?.length) {
      const projectContacts = projectsData.getProjects.edges.map(({ contact }) => contact);
      const projectSecondaryContacts = projectsData.getProjects.edges.map(({ secondaryContacts }) => secondaryContacts);
      mergedContacts.push(...compact(projectContacts), ...compact(flatten(projectSecondaryContacts)));
    }

    return chain(mergedContacts)
      .uniqBy('id')
      .orderBy([({ displayName }) => displayName.toLocaleLowerCase()])
      .value();
  }, [contactsData?.getContacts.edges, galleryContacts, projectsData?.getProjects.edges]);

  const contactsList = useMemo(
    () => contacts.filter(contact => !values.secondaryContactIds || !values.secondaryContactIds.includes(contact.id)),
    [contacts, values.secondaryContactIds],
  );

  const secondaryContactsList = useMemo(
    () => contacts.filter(contact => !values.contactId || contact.id !== values.contactId),
    [contacts, values.contactId],
  );

  const contactCount = useMemo(() => contactsData?.getContacts?._count || 0, [contactsData?.getContacts?._count]);

  const projects = useMemo(() => {
    if (projectsData?.getProjects.edges) {
      return uniqBy(projectsData.getProjects.edges, 'id');
    }

    return [];
  }, [projectsData?.getProjects.edges]);

  const galleryPresets: {
    _count: number;
    edges: GetGalleryPresetsQuery['getGalleryPresets']['edges'];
  } = useMemo(() => {
    if (!presetsData?.getGalleryPresets) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...presetsData.getGalleryPresets.edges], 'id'),
      _count: presetsData.getGalleryPresets._count,
    };
  }, [presetsData]);

  const hasFetchAllProjects = useMemo(
    () => projects?.length === projectsData?.getProjects?._count,
    [projectsData, projects],
  );

  const isAccessCodeGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE,
    [values.accessPolicy],
  );

  const isPublicGallery = useMemo(() => values.accessPolicy === GalleryAccessPolicy.PUBLIC, [values.accessPolicy]);

  const isOldAccessCodeGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.OLD_ACCESS_CODE,
    [values.accessPolicy],
  );

  const hdDigitalProducts = useMemo(() => {
    const productEdges =
      digitalProductsData?.getDigitalProducts?.edges.filter(
        p =>
          p.digitalProductConfig?.downloadContent === GalleryDownload.HD ||
          p.digitalProductConfig?.downloadContent === GalleryDownload.HD_WEB,
      ) || [];

    return {
      _count: productEdges.length,
      edges: productEdges,
    };
  }, [digitalProductsData?.getDigitalProducts?.edges]);

  useEffect(() => {
    const presetId = initialValues.galleryPresetId || values.galleryPresetId;
    if (presetId) {
      getPreset({
        variables: {
          where: {
            id: presetId,
          },
        },
      });
    }
  }, [getPreset, initialValues.galleryPresetId, values.galleryPresetId]);

  useEffect(() => {
    if (
      values.status === GalleryStatus.ONLINE &&
      values.availableAt &&
      dayjs(values.availableAt).isSameOrAfter(dayjs())
    ) {
      setFieldValue('availableAt', null);
    }
  }, [values.status, values.availableAt, setFieldValue]);

  useEffect(() => {
    const { initialValue, value, touched: isTouched } = getFieldMeta('url');
    if (!initialValue && !value && !isTouched) {
      setFieldValue('url', kebabCase(values.name));
    }
  }, [getFieldMeta, setFieldValue, values.name]);

  useEffect(() => {
    if (!values.catalogId) {
      let defaultCatalog = catalogs.edges.find(catalog => catalog.isDefault);

      if (!defaultCatalog) {
        defaultCatalog = catalogs.edges[0];
      }

      if (defaultCatalog) {
        setFieldValue('catalogId', defaultCatalog.id);
      }
    }
  }, [catalogs.edges, setFieldValue, values.catalogId]);

  useEffect(() => {
    if (!values.workmode && userSettingsData?.getCompany?.settings) {
      setFieldValue('workmode', userSettingsData?.getCompany.settings.defaultGalleryWorkmode);
    }
  }, [setFieldValue, userSettingsData?.getCompany?.settings, values.workmode]);

  useEffect(() => {
    if (values.projectId) {
      const project = projects.find(project => project.id === values.projectId);
      if (!project) {
        return;
      }
      setValues(oldValues => ({
        ...oldValues,
        name: oldValues.name || project.name,
        url: oldValues.url || kebabCase(project.name),
        contactId: oldValues.contactId || project.contactId,
        secondaryContactIds: oldValues.secondaryContactIds || project.secondaryContactsIds,
        shootedAt: oldValues.shootedAt || project.startTime,
      }));
    }
  }, [projects, setValues, values.projectId]);

  useEffect(() => {
    if (
      (!initialValues.accessCode &&
        initialValues.accessPolicy === GalleryAccessPolicy.PUBLIC &&
        values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE) ||
      initialValues.contactId !== values.contactId
    ) {
      setFieldValue('accessCode', generateId({ size: 6 }));
    }

    if (initialValues.accessCode && initialValues.contactId === values.contactId) {
      setFieldValue('accessCode', initialValues.accessCode);
    }
  }, [
    initialValues.accessCode,
    initialValues.accessPolicy,
    initialValues.contactId,
    setFieldValue,
    values.accessPolicy,
    values.contactId,
  ]);

  // Reset included products properties
  useEffect(() => {
    if (isCatalogsLoading || isProductsLoading) {
      return;
    }

    const isCatalogEmpty =
      !values.catalogId ||
      !digitalProductsData?.getDigitalProducts ||
      digitalProductsData?.getDigitalProducts._count === 0;

    if (isCatalogEmpty && values.enableIncludedProduct) {
      setFieldValue('enableIncludedProduct', false);
    }

    if (values.workmode === GalleryWorkmode.RETOUCH_FIRST) {
      setFieldValue('hasAllPhotosIncluded', false);
    }

    if (isCatalogEmpty || !values.enableIncludedProduct) {
      setFieldValue('hasAllPhotosIncluded', false);
      setFieldValue('includedProductId', undefined);
      setFieldValue('includedPhotosCount', undefined);
    }

    if (
      isCatalogEmpty ||
      !values.enableIncludedProduct ||
      values.workmode === GalleryWorkmode.RETOUCH_FIRST ||
      (isPublicGallery && values.hasAllPhotosIncludedForGuest) ||
      (!values.hasAllPhotosIncluded && values.hasAllPhotosIncludedForGuest)
    ) {
      setFieldValue('hasAllPhotosIncludedForGuest', false);
      setFieldValue('includedProductIdForGuest', undefined);
    }

    if (isCatalogEmpty && values.enableMandatoryProduct) {
      setFieldValue('enableMandatoryProduct', false);
    }

    if (isCatalogEmpty || !values.enableMandatoryProduct) {
      setFieldValue('mandatoryProductId', undefined);
    }
  }, [
    isPublicGallery,
    setFieldValue,
    values.accessPolicy,
    values.hasAllPhotosIncludedForGuest,
    values.hasAllPhotosIncluded,
    values.enableIncludedProduct,
    values.catalogId,
    digitalProductsData?.getDigitalProducts,
    isCatalogsLoading,
    isProductsLoading,
    values.enableMandatoryProduct,
    values.workmode,
  ]);

  // Get the first digital product to set default included one for the principal client.
  useEffect(() => {
    if (
      values.enableIncludedProduct &&
      digitalProductsData?.getDigitalProducts &&
      digitalProductsData?.getDigitalProducts._count > 0
    ) {
      let includedProductId = digitalProductsData?.getDigitalProducts.edges.find(
        p => p.id === values.includedProductId,
      )?.id;

      if (!includedProductId) {
        includedProductId = digitalProductsData?.getDigitalProducts.edges[0].id;
      }

      setFieldValue('includedProductId', includedProductId);
    }
  }, [
    digitalProductsData?.getDigitalProducts,
    digitalProductsData?.getDigitalProducts._count,
    digitalProductsData?.getDigitalProducts.edges,
    initialValues.catalogId,
    setFieldValue,
    values.catalogId,
    values.enableIncludedProduct,
    values.includedProductId,
  ]);

  // Get the first digital product to set default included one for guest.
  useEffect(() => {
    if (
      values.enableIncludedProduct &&
      values.hasAllPhotosIncluded &&
      values.hasAllPhotosIncludedForGuest &&
      digitalProductsData?.getDigitalProducts &&
      digitalProductsData?.getDigitalProducts._count > 0
    ) {
      let includedProductIdForGuest = digitalProductsData?.getDigitalProducts.edges.find(
        p => p.id === values.includedProductIdForGuest,
      )?.id;

      if (!includedProductIdForGuest) {
        includedProductIdForGuest = digitalProductsData?.getDigitalProducts.edges[0].id;
      }

      setFieldValue('includedProductIdForGuest', includedProductIdForGuest);
    }
  }, [
    digitalProductsData?.getDigitalProducts,
    setFieldValue,
    values.enableIncludedProduct,
    values.hasAllPhotosIncluded,
    values.hasAllPhotosIncludedForGuest,
    values.includedProductIdForGuest,
  ]);

  const renderAccessPolicyValues = useCallback(
    (translationsPath: string) => {
      const keys = Object.keys(GalleryAccessPolicy);
      const values = compact(
        keys.map(key => {
          if (key === GalleryAccessPolicy.OLD_PUBLIC || key === GalleryAccessPolicy.OLD_ACCESS_CODE) {
            return;
          }

          return (
            <Select.Option key={key} value={key} title={''}>
              {t(`${translationsPath}.${camelCase(key.toLowerCase())}`)}
            </Select.Option>
          );
        }),
      );
      return values;
    },
    [t],
  );

  const handleAccessPolicyChange = useCallback(
    (value: GalleryAccessPolicy) => {
      onAccessTypeChanged(value);

      if (value === GalleryAccessPolicy.ACCESS_CODE) {
        const currentAccessCode = getFieldProps('accessCode').value;
        if (currentAccessCode === null) {
          setFieldValue('accessCode', '');
        }
      }
      setIsAccessCodeInputVisible(value === GalleryAccessPolicy.ACCESS_CODE);
    },
    [getFieldProps, onAccessTypeChanged, setFieldValue],
  );

  useEffect(() => {
    if (galleryPresetData?.getGalleryPreset.id) {
      const {
        getGalleryPreset: { accessPolicy, catalog, locale, workmode },
      } = galleryPresetData;

      setValues(oldValues => ({
        ...oldValues,
        accessPolicy,
        catalogId: catalog?.id,
        locale,
        workmode,
      }));

      setIsAccessCodeInputVisible(accessPolicy === GalleryAccessPolicy.ACCESS_CODE);
    }
  }, [galleryPresetData, setIsAccessCodeInputVisible, setValues]);

  const handleLoadMoreGalleryPresets = useCallback(async () => {
    const params = { ...presetsQueryParams, page: presetsQueryParams.page + 1 };
    await fetchMorePresets({
      variables: {
        where: params,
      },
    });

    setPresetsQueryParams(params);
  }, [fetchMorePresets, presetsQueryParams]);

  const handleLoadMoreProjects = useCallback(async () => {
    const params = { ...projectsQueryParams, page: projectsQueryParams.page + 1 };
    await fetchMoreProjects({
      variables: {
        where: params,
      },
    });

    setProjectsQueryParams(params);
  }, [fetchMoreProjects, projectsQueryParams]);

  const handleLoadMoreContacts = useCallback(async () => {
    const params = { ...contactsQueryParams, page: contactsQueryParams.page + 1 };
    await fetchMoreContacts({
      variables: {
        where: params,
      },
    });

    setContactsQueryParams(params);
  }, [contactsQueryParams, fetchMoreContacts]);

  const handleLoadMoreCatalogs = useCallback(async () => {
    const params = { ...catalogsQueryParams, page: catalogsQueryParams.page + 1 };

    await fetchMoreCatalogs({
      variables: {
        where: params,
      },
    });

    setCatalogsQueryParams(params);
  }, [catalogsQueryParams, fetchMoreCatalogs]);

  const handleSearchProject = useCallback(async (searchTerms: string) => {
    setProjectsQueryParams({
      ...EDGES_PARAMS,
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  const handleSearchContact = useCallback((searchTerms: string) => {
    setContactsQueryParams({
      ...EDGES_PARAMS,
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  const handleLoadMoreDigitalProducts = useCallback(() => {
    digitalProductsPage.current += 1;
    fetchMoreProducts({
      variables: {
        where: {
          ...DIGITAL_PRODUCTS_PARAMS,
          page: digitalProductsPage.current,
        },
      },
    });
  }, [fetchMoreProducts]);

  return (
    <Form layout="vertical">
      {hasCRM && (
        <Flex gap="middle">
          <Form.Item label={t('app.gallery.project')} name="projectId" hasFeedback={false}>
            <Select
              name="projectId"
              size="large"
              showSearch
              optionFilterProp="children"
              onSearch={handleSearchProject}
              loadMore={handleLoadMoreProjects}
              hasFetchAll={hasFetchAllProjects}
              loading={isProjectsLoading}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Select.Option
                key={-1}
                // Because ant design select item value of type Key can only be string or number and not null
                // @ts-ignore
                value={null}
                title={''}
              >
                -
              </Select.Option>
              {projects.map(project => (
                <Select.Option key={project.id} value={project.id} title={''}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!galleryId && !initialValues.galleryPresetId && (
            <Form.Item label={t('app.gallery.preset.label')} name="galleryPresetId" hasFeedback={false}>
              <Select
                name="galleryPresetId"
                size="large"
                loadMore={handleLoadMoreGalleryPresets}
                hasFetchAll={galleryPresets._count === galleryPresets.edges.length}
                loading={isPresetsLoading}
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Select.Option
                  key={-1}
                  // Because ant design select item value of type Key can only be string or number and not null
                  // @ts-ignore
                  value={null}
                  title={''}
                >
                  -
                </Select.Option>
                {galleryPresets.edges.map(preset => (
                  <Select.Option key={preset.id} value={preset.id} title={''}>
                    {preset.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Flex>
      )}
      <Flex gap="middle">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input
            name="name"
            placeholder={t('app.common.name')}
            size="large"
            onChange={({ target: { value } }) => {
              setFieldValue('name', value);
              const { touched: isTouched, initialValue } = getFieldMeta('url');
              if (!initialValue && !isTouched) {
                setFieldValue('url', kebabCase(value));
              }
            }}
          />
        </Form.Item>
        <Form.Item label={t('app.common.customUrl')} name="url" hasFeedback={false}>
          <Input
            name="url"
            placeholder={t('app.common.customUrl')}
            size="large"
            onBlur={({ target: { value } }) => setFieldValue('url', kebabCase(value))}
            onChange={({ target: { value } }) => {
              setFieldTouched('url', true);
              setFieldValue('url', value);
            }}
          />
        </Form.Item>
      </Flex>
      <Flex>
        {values.accessPolicy !== GalleryAccessPolicy.OLD_PUBLIC &&
          values.accessPolicy !== GalleryAccessPolicy.OLD_ACCESS_CODE && (
            <Form.Item name="accessPolicy" label={t('app.gallery.accessType')} required hasFeedback={false}>
              <Select
                name="accessPolicy"
                size="large"
                onChange={handleAccessPolicyChange}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {renderAccessPolicyValues('app.gallery.accessTypes')}
              </Select>
            </Form.Item>
          )}
      </Flex>
      <Flex gap="middle">
        <Form.Item
          label={t('app.common.contact')}
          name="contactId"
          required={isAccessCodeInputAvailable}
          hasFeedback={false}
        >
          <Select
            name="contactId"
            size="large"
            showSearch
            onSearch={handleSearchContact}
            optionFilterProp="children"
            loadMore={handleLoadMoreContacts}
            hasFetchAll={contactCount - (values?.secondaryContactIds?.length || 0) <= contacts.length}
            loading={isContactsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {contactsList.map(contact => (
              <Select.Option key={contact.id} value={contact.id} title={''}>
                {contact.displayName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {(isAccessCodeGallery || isOldAccessCodeGallery) && (
          <Form.Item
            label={isOldAccessCodeGallery ? t('app.gallery.oldAccessCode') : t('app.gallery.accessCode')}
            name="accessCode"
            required={isAccessCodeInputAvailable}
            hasFeedback={false}
          >
            <Input
              name="accessCode"
              placeholder={t('app.gallery.accessCode')}
              size="large"
              disabled={!isAccessCodeInputAvailable || isOldAccessCodeGallery}
            />
          </Form.Item>
        )}
      </Flex>
      {!!(values as EditGalleryFormValues).id &&
        isAccessCodeGallery &&
        !!initialValues.accessCode &&
        values.accessCode !== initialValues.accessCode &&
        values.contactId === initialValues.contactId && (
          <WarningText size="medium">
            <Icon name="warning" size={24} /> {t('app.gallery.warning.accessCodeModification')}
          </WarningText>
        )}
      <Flex>
        <Form.Item label={t('app.common.secondaryContacts')} name="secondaryContactIds" hasFeedback={false}>
          <Select
            name="secondaryContactIds"
            mode="multiple"
            size="large"
            onSearch={handleSearchContact}
            autoClearSearchValue
            showSearch
            optionFilterProp="children"
            loadMore={handleLoadMoreContacts}
            hasFetchAll={contactCount - (values?.contactId ? 1 : 0) <= contacts.length}
            loading={isContactsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {secondaryContactsList.map(contact => (
              <Select.Option key={contact.id} value={contact.id} title={''}>
                {contact.displayName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      {!(values as EditGalleryFormValues).id && (
        <Flex gap="middle">
          <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="availableAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
          <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="expiredAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
        </Flex>
      )}
      {(values as EditGalleryFormValues).id && (
        <>
          <Flex gap="middle">
            <Form.Item label={t('app.common.status')} name="status" required hasFeedback={false}>
              <Select name="status" size="large" getPopupContainer={trigger => trigger.parentNode}>
                {Object.keys(GalleryStatus)
                  .filter(status => status !== GalleryStatus.ARCHIVED)
                  .map(status => (
                    <Select.Option key={status} value={status} title={''}>
                      {t(`app.gallery.status.${status.toLowerCase()}`)}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {values.status === GalleryStatus.OFFLINE && (
              <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
                <DatePicker
                  showNow={false}
                  name="availableAt"
                  size="large"
                  showTime={{ format: 'HH:mm', defaultOpenValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
                  format="LLL"
                />
              </Form.Item>
            )}
          </Flex>
          <Flex>
            <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
              <DatePicker
                showNow={false}
                name="expiredAt"
                size="large"
                showTime={{ format: 'HH:mm', defaultOpenValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
                format="LLL"
              />
            </Form.Item>
          </Flex>
        </>
      )}
      <Flex>
        <Form.Item label={t('app.gallery.shootedAt')} name="shootedAt" hasFeedback={false}>
          <DatePicker name="shootedAt" size="large" format="YYYY-MM-DD" minuteStep={5} />
        </Form.Item>
      </Flex>
      <Flex gap="middle">
        <Form.Item label={t('app.gallery.locale.label')} name="locale" required hasFeedback={false}>
          <Select name="locale" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(Locale).map(lang => (
              <Select.Option key={lang} value={lang.toLowerCase()} title={''}>
                {t(`app.locale.${lang.toLowerCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('app.gallery.workmode.label')} name="workmode" required hasFeedback={false}>
          <Select name="workmode" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(GalleryWorkmode).map(mode => (
              <Select.Option key={mode} value={mode} title={''}>
                {t(`app.gallery.settings.defaultWorkmode.${camelCase(mode)}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      <Flex>
        <Form.Item label={t('app.common.catalog', { count: 1 })} name="catalogId" required hasFeedback={false}>
          <Select
            name="catalogId"
            size="large"
            loadMore={handleLoadMoreCatalogs}
            hasFetchAll={catalogs._count === catalogs.edges.length}
            loading={isCatalogsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {catalogs.edges.map(catalog => (
              <Select.Option key={catalog.id} value={catalog.id} title={''}>
                {catalog.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      {values.catalogId && digitalProductsData && digitalProductsData.getDigitalProducts._count > 0 && (
        <>
          <Fieldset className={`Fieldset ${!values.enableIncludedProduct ? 'Fieldset--NoContent' : ''}`}>
            <Legend>
              <Form.Item
                name="enableIncludedProduct"
                label={t('app.gallery.enableIncludedProduct')}
                className="ant-form-item--switch"
              >
                <Switch name="enableIncludedProduct" />
              </Form.Item>
            </Legend>
            {values.enableIncludedProduct && (
              <>
                <Flex gap="middle">
                  <Form.Item
                    label={t('app.common.products', { count: 1 })}
                    name="includedProductId"
                    required
                    hasFeedback={false}
                  >
                    <Select
                      name="includedProductId"
                      size="large"
                      loadMore={handleLoadMoreDigitalProducts}
                      hasFetchAll={
                        (digitalProductsData?.getDigitalProducts._count || 0) ===
                        (digitalProductsData?.getDigitalProducts.edges.length || 0)
                      }
                      loading={isProductsLoading}
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      {digitalProductsData?.getDigitalProducts.edges.map(digitalProduct => (
                        <Select.Option key={digitalProduct.id} value={digitalProduct.id} title={''}>
                          {digitalProduct.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {values.workmode !== GalleryWorkmode.RETOUCH_FIRST && (
                    <Form.Item
                      label={t('app.gallery.hasAllPhotosIncludedPublic.label')}
                      name="hasAllPhotosIncluded"
                      required
                    >
                      <Switch name="hasAllPhotosIncluded" />
                    </Form.Item>
                  )}
                </Flex>
                {!values.hasAllPhotosIncluded && (
                  <Flex gap="middle">
                    <Form.Item
                      name="includedPhotosCount"
                      label={t('app.common.quantity')}
                      required={!values.hasAllPhotosIncluded}
                      hasFeedback={false}
                    >
                      <InputNumber name="includedPhotosCount" min={minIncludedCount} size="large" />
                    </Form.Item>
                    <Flex flex={1}>
                      <div />
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </Fieldset>
          {values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE && values.hasAllPhotosIncluded && (
            <Fieldset className={`Fieldset ${!values.hasAllPhotosIncludedForGuest ? 'Fieldset--NoContent' : ''}`}>
              <Legend>
                <Form.Item
                  name="hasAllPhotosIncludedForGuest"
                  label={t('app.gallery.hasAllPhotosIncludedForGuest.label')}
                  className="ant-form-item--switch"
                >
                  <Switch name="hasAllPhotosIncludedForGuest" />
                </Form.Item>
              </Legend>
              {values.hasAllPhotosIncludedForGuest && (
                <Flex gap="middle">
                  <Form.Item
                    label={t('app.common.products', { count: 1 })}
                    name="includedProductIdForGuest"
                    required
                    hasFeedback={false}
                  >
                    <Select
                      name="includedProductIdForGuest"
                      size="large"
                      loadMore={handleLoadMoreDigitalProducts}
                      hasFetchAll={
                        (digitalProductsData?.getDigitalProducts._count || 0) ===
                        (digitalProductsData?.getDigitalProducts.edges.length || 0)
                      }
                      loading={isProductsLoading}
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      {digitalProductsData?.getDigitalProducts.edges.map(digitalProduct => (
                        <Select.Option key={digitalProduct.id} value={digitalProduct.id} title={''}>
                          {digitalProduct.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Flex>
              )}
            </Fieldset>
          )}
          <FieldsetStyled
            className={clsx(
              `Fieldset`,
              !values.enableMandatoryProduct && 'Fieldset--NoContent',
              !!errors.mandatoryProductId && 'Fieldset--Error',
            )}
          >
            <Legend>
              <MandatoryFormItemStyled
                name="enableMandatoryProduct"
                label={t('app.gallery.enableMandatoryProduct')}
                className="ant-form-item--switch"
              >
                <Switch name="enableMandatoryProduct" />
              </MandatoryFormItemStyled>
            </Legend>
            {values.enableMandatoryProduct && (
              <>
                <Flex>
                  {hdDigitalProducts._count === 0 && (
                    <AlertStyled message={t('app.gallery.noHDDigitalProducts')} type="info" showIcon />
                  )}
                  {hdDigitalProducts._count > 0 && (
                    <Form.Item
                      label={t('app.common.products', { count: 1 })}
                      name="mandatoryProductId"
                      required
                      hasFeedback={false}
                    >
                      <Select
                        name="mandatoryProductId"
                        size="large"
                        loadMore={handleLoadMoreDigitalProducts}
                        hasFetchAll={(hdDigitalProducts._count || 0) === (hdDigitalProducts.edges.length || 0)}
                        loading={isProductsLoading}
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {hdDigitalProducts.edges.map(digitalProduct => (
                          <Select.Option key={digitalProduct.id} value={digitalProduct.id} title={''}>
                            {digitalProduct.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Flex>
              </>
            )}
          </FieldsetStyled>
        </>
      )}
      {values.catalogId && digitalProductsData && digitalProductsData.getDigitalProducts._count === 0 && (
        <AlertStyled message={t('app.gallery.noDigitalProducts')} type="info" showIcon />
      )}
      {/* TODO: enable isShopEnable when feature is implemented */}
      {/* <Form.Item label={t('app.gallery.isShopEnable')} name="isShopEnable" className="ant-form-item--switch" hasFeedback={false}>
          <Switch name="isShopEnable" />
        </Form.Item> */}
      <Flex>
        <Form.Item label={t('app.gallery.video.label')} name="video" hasFeedback={false}>
          <Input name="video" placeholder={t('app.gallery.video.placeholder')} size="large" />
        </Form.Item>
      </Flex>
      <Form.Item
        label={t('app.gallery.inPortfolio')}
        name="inPortfolio"
        className="ant-form-item--switch"
        hasFeedback={false}
      >
        <Switch name="inPortfolio" />
      </Form.Item>
      <Flex justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditGalleryFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewGalleryFormPayload {
  values: NewGalleryFormValues;
  formikBag: FormikBag<NewGalleryFormProps, NewGalleryFormValues>;
}

export interface NewGalleryFormProps extends GalleryFormProps {
  onSubmit: (payload: NewGalleryFormPayload) => void;
  defaultValues: NewGalleryFormValues;
}

export interface EditGalleryFormPayload {
  values: EditGalleryFormValues;
  formikBag: FormikBag<EditGalleryFormProps, EditGalleryFormValues>;
}

export interface EditGalleryFormProps extends GalleryFormProps {
  onSubmit: (payload: EditGalleryFormPayload) => void;
  defaultValues: EditGalleryFormValues;
}

const isFiveMinutesRound = function (value?: string | null) {
  return value ? dayjs(value).get('minutes') % 5 === 0 : true;
};

const gallerySchema = yup.object({
  projectId: yup.number().nullable(),
  galleryPresetId: yup.number().nullable(),
  name: yup.string().trim().required(),
  url: yup.string().nullable(),
  video: yup
    .string()
    .nullable()
    .test('is-video-url-valid', i18n.t('app.gallery.wrongVideoLink'), value => {
      if (value) {
        return /youtube\.com\/|youtu\.be\/|vimeo\.com\//.test(value);
      }
      return true;
    }),
  contactId: yup.number().when('accessPolicy', {
    is: GalleryAccessPolicy.ACCESS_CODE,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  secondaryContactIds: yup.array().nullable(),
  status: yup.mixed<GalleryStatus>().required(),
  locale: yup.mixed<Locale>().required(),
  availableAt: yup
    .string()
    .trim()
    .nullable()
    .test('availableAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound),
  expiredAt: yup
    .string()
    .trim()
    .test('expiredAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound)
    .when('availableAt', {
      is: (availableAt: string) => !!availableAt,
      // @ts-ignore
      then: yup.string().nullable().after(yup.ref('availableAt')),
      otherwise: yup.string().nullable(),
    }),
  shootedAt: yup.string().trim().nullable(),
  accessPolicy: yup.mixed<GalleryAccessPolicy>().required(),
  accessCode: yup.string().trim().when('accessPolicy', {
    is: GalleryAccessPolicy.ACCESS_CODE,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  inPortfolio: yup.boolean().required(),
  isShopEnable: yup.boolean().required(),
  workmode: yup.mixed<GalleryWorkmode>().required(),
  catalogId: yup.number(),
  enableIncludedProduct: yup.boolean().required(),
  includedProductId: yup.number().when('enableIncludedProduct', {
    is: true,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  includedPhotosCount: yup
    .number()
    .typeError(i18n.t('app.forms.errors.postiveIntegerOrZero'))
    .integer(i18n.t('app.forms.errors.postiveIntegerOrZero'))
    .min(0)
    .when(['enableIncludedProduct', 'hasAllPhotosIncluded'], {
      is: (enableIncludedProduct: boolean, hasAllPhotosIncluded: boolean) =>
        enableIncludedProduct && !hasAllPhotosIncluded,
      then: yup.number().required(),
      otherwise: yup.number().nullable(),
    }),
  hasAllPhotosIncluded: yup.boolean().required(),
  hasAllPhotosIncludedForGuest: yup.boolean().required(),
  includedProductIdForGuest: yup.number().when('hasAllPhotosIncludedForGuest', {
    is: true,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  enableMandatoryProduct: yup.boolean().required(),
  mandatoryProductId: yup.number().when('enableMandatoryProduct', {
    is: true,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
});

const newGallerySchema: yup.SchemaOf<NewGalleryFormValues> = gallerySchema.defined();
const editGallerySchema: yup.SchemaOf<EditGalleryFormValues> = gallerySchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewGalleryForm = withFormik<NewGalleryFormProps, NewGalleryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    enableIncludedProduct: true,
    enableMandatoryProduct: false,
  }),
  validationSchema: newGallerySchema,
})(GalleryForm);

export const EditGalleryForm = withFormik<EditGalleryFormProps, EditGalleryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editGallerySchema,
})(GalleryForm);
