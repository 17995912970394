import React, { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Text from 'Components/Atoms/Text';

import LabeledSwitch from 'Components/Molecules/LabeledSwitch';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { GET_COMPANY } from 'Operations/Queries/Company/GetCompany';

import { UPDATE_COMPANY_SETTINGS } from 'Operations/Mutations/Company/UpdateCompanySettings';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const Address = styled(Text)`
  margin-left: ${Metrics.baseMargin}px;

  span {
    display: block;
  }
`;

const OptionText = styled(Text)`
  margin-top: ${Metrics.baseMargin}px;
`;

const MyActivityOrderTab = () => {
  const { t } = useContext(LocalizationContext);

  const { data: companyData } = useQuery(GET_COMPANY, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  const handleOnChange = useCallback(
    async (value: boolean) => {
      await updateCompanySettings({
        variables: {
          data: {
            mustReceiveOrder: value,
          },
        },
      });
    },
    [updateCompanySettings],
  );

  if (!companyData || !companyData?.getCompany) {
    return null;
  }

  const { __typename, ...company } = companyData?.getCompany;

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      <LabeledSwitch
        checked={company.settings.mustReceiveOrder || false}
        label={t('app.company.settings.mustReceiveOrder.label')}
        onCheckChanged={handleOnChange}
      />
      <Text>{t('app.company.settings.mustReceiveOrder.description')}</Text>
      <OptionText weight="bold">{t('app.company.settings.mustReceiveOrder.address')}</OptionText>
      <Address>
        <span>{company.street}</span>
        <span>
          {company.zipcode} {company.city}
        </span>
      </Address>
    </StyledTableContentContainer>
  );
};

export default MyActivityOrderTab;
