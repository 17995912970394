import React, { useCallback, useContext, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';

import Header from 'Components/Molecules/Header';
import CatalogsTable, { HandleChangeProps } from 'Components/Molecules/Tables/CatalogsTable';

import { NewCatalogFormPayload } from 'Forms/Catalog';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import getErrorCode from 'Helpers/GetErrorCode';

import { GET_PAGINATED_CATALOGS } from 'Operations/Queries/Catalog/GetPaginatedCatalogs';

import { CREATE_CATALOG } from 'Operations/Mutations/Catalog/CreateCatalog';
import { DELETE_CATALOG } from 'Operations/Mutations/Catalog/DeleteCatalog';

const StyledTableContentContainer = styled(ContentContainer)`
  margin-top: ${Metrics.baseMargin}px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const PER_PAGE = 20;

const CatalogsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { data, loading: isLoading } = useQuery(GET_PAGINATED_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      where: {
        page,
        perPage: PER_PAGE,
      },
    },
  });
  const [createCatalog] = useMutation(CREATE_CATALOG);
  const [deleteCatalog] = useMutation(DELETE_CATALOG);

  const handleFetchMore = useCallback(({ page: newPage }: HandleChangeProps) => {
    setPage(newPage);
  }, []);

  const handleOnSubmit = useCallback(
    async ({ values, formikBag }: NewCatalogFormPayload) => {
      try {
        const res = await createCatalog({
          variables: {
            data: values,
          },
        });
        message.success(t('app.message.catalog.create.success'));

        formikBag.setSubmitting(false);
        closeModal();
        navigate(`/app/catalogs/${res.data?.createCatalog.id}`);
      } catch (error) {
        console.log(error);
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createCatalog, navigate, t],
  );

  const handleOnCreateGalleryClicked = useCallback(() => {
    const defaultValues = {
      name: '',
      isDefault: false,
      vat: undefined,
    };

    openModal('CATALOG', {
      name: 'new',
      onSubmit: handleOnSubmit,
      defaultValues,
    });
  }, [handleOnSubmit, openModal]);

  const handleDeleteClick = async ({ id }: { id: number }) => {
    try {
      await deleteCatalog({
        variables: {
          where: {
            id,
          },
        },
        update(cache, result) {
          if (result.data?.deleteCatalog.id) {
            const normalizedId = cache.identify({
              id,
              __typename: result.data.deleteCatalog.__typename,
            });

            cache.evict({ id: normalizedId });
          }
          cache.gc();
        },
      });

      message.success(t('app.message.catalog.delete.success'));
    } catch (error) {
      const errorCode = getErrorCode(error);
      console.log(errorCode);
      if (errorCode) {
        message.error(t(`app.message.catalog.delete.error.${camelCase(errorCode)}`));
      } else {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    }
  };

  const createButton = (
    <CreateButton key="add" onClick={handleOnCreateGalleryClicked} size="large" type="primary">
      <Icon name="add" />
      {t('app.common.add')}
    </CreateButton>
  );

  return (
    <>
      <Header
        title={t('app.common.catalog', { count: 2 })}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.catalog', { count: 2 }) },
        ]}
        buttons={[createButton]}
      />
      <StyledTableContentContainer>
        <CatalogsTable
          data={data?.getPaginatedCatalogs.edges || []}
          handleOnChange={handleFetchMore}
          isLoading={isLoading}
          total={data?.getPaginatedCatalogs._count || 0}
          paginationSize={PER_PAGE}
          handleDeleteConfirm={handleDeleteClick}
        />
      </StyledTableContentContainer>
    </>
  );
};

export default CatalogsIndex;
