import { gql } from 'Operations/__generated__';

export const DUPLICATE_PRODUCT = gql(/* GraphQL */ `
  mutation DuplicateProduct($where: IdInput!) {
    duplicateProduct(where: $where) {
      id
      name
      description
      weight
      previewAsset {
        downloadUrl
      }
      category {
        id
        name
      }
      contactId
      contact {
        id
        firstname
        lastname
        company
        displayName @client
      }
      maxPhotoCount
      orderedByFotostudio
      photoSizes {
        width
        height
      }
    }
  }
`);
