import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { EditProductForm, EditProductFormProps, NewProductForm, NewProductFormProps } from 'Forms/Product';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

const StyledIcon = styled(Icon)`
  margin-right: ${Metrics.tinyMargin}px;
`;
const StyledTitle = styled(Title)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${Metrics.doubleBaseMargin}px 0 ${Metrics.mediumMargin}px 0;
  font-style: italic;
  color: ${Colors.white};
`;
const StyledText = styled(Text)`
  margin-bottom: ${Metrics.mediumMargin}px;
  color: ${Colors.white};
`;

interface NewProductModalProps extends NewProductFormProps {
  name: 'new';
}
interface EditProductModalProps extends EditProductFormProps {
  name: 'edit';
}

const ProductModal = (props: NewProductModalProps | EditProductModalProps) => {
  const { t } = useContext(LocalizationContext);
  const { closeModal } = useModals();

  return (
    <ModalContainer
      title={t(`app.product.modal.${props.name}`)}
      icon={Images.tag}
      subtitle={
        <>
          <StyledTitle level="h5">
            <StyledIcon color={Colors.white} name="tips" size={22} />
            <span>{t('app.product.modal.subtitle')}</span>
          </StyledTitle>
          <ReactMarkdown
            children={t('app.product.modal.content', {
              link: '/app/contacts',
            })}
            components={{
              p: ({ children }) => <StyledText size="medium">{children}</StyledText>,
              a: ({ children, ...props }) => (
                <Link
                  to={props.href || '.'}
                  style={{
                    color: Colors.white,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {children}
                </Link>
              ),
            }}
          />
        </>
      }
    >
      {props.name === 'edit' ? (
        <EditProductForm
          onSubmit={props.onSubmit}
          defaultValues={props.defaultValues}
          isAdmin={props.isAdmin}
          minPrice={props.minPrice}
        />
      ) : (
        <NewProductForm
          onSubmit={props.onSubmit}
          defaultValues={props.defaultValues}
          isAdmin={props.isAdmin}
          minPrice={props.minPrice}
        />
      )}
    </ModalContainer>
  );
};

export default ProductModal;
