import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { DatePicker, Form, Select, Switch } from 'formik-antd';

import Fieldset from 'Components/Atoms/Fieldset';

import FileSelect from 'Components/Molecules/Form/FileSelect';
import TextInput from 'Components/Molecules/Form/TextInput';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import { GET_CONTACT_ASSOCIATED_VALUES } from 'Operations/Queries/Contact/GetContactAssociatedValues';

export interface NewContactFormValues {
  contactTypeId: number;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  phone?: string | null;
  birthdate?: string | null;
  birthdayEmail?: boolean;
  photo?: Blob | null;
  favorite?: boolean;
  street?: string | null;
  zipcode?: string | null;
  city?: string | null;
  stateId?: number | null;
  countryId?: number | null;
  company?: string | null;
  vat?: string | null;
  prospectOriginId?: number | null;
  graphicalIdentityId?: number | null;
  tags?: string[];
  taxCode?: string;
}

export interface EditContactFormValues extends NewContactFormValues {
  id: number;
}

const ITALY_COUNTRY_ID = 109;
const SPAIN_COUNTRY_ID = 203;

const PhotoPreview = styled.img`
  width: 100px;
  height: auto;
  margin-right: ${Metrics.baseMargin}px;
  margin-bottom: 4px;
`;

const ContactForm = <T extends EditContactFormValues | NewContactFormValues>({
  isSubmitting,
  values,
  setFieldValue,
  photoUrl,
}: FormikProps<T> & { providerTypeId: number; photoUrl?: string }) => {
  const { t, locale } = useContext(LocalizationContext);

  const [image, setImage] = useState<string | undefined>(photoUrl);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const { data } = useQuery(GET_CONTACT_ASSOCIATED_VALUES, {
    fetchPolicy: 'cache-and-network',
  });

  const isTaxCodeEnable = useMemo(
    () => values.countryId && (values.countryId === ITALY_COUNTRY_ID || values.countryId === SPAIN_COUNTRY_ID),
    [values.countryId],
  );

  const countryStates = useMemo(
    () => data?.getCountries.edges.find(country => country.id === values.countryId)?.states,
    [values.countryId, data?.getCountries.edges],
  );

  useEffect(() => {
    if (data && data.me && data.me.contactTypes?.edges?.length > 0 && values.contactTypeId === 0) {
      setFieldValue('contactTypeId', data.me.contactTypes.edges[0].id);
    }
  }, [data, setFieldValue, values.contactTypeId]);

  // Reset taxCode if country is not Italy
  useEffect(() => {
    if (!isTaxCodeEnable) {
      setFieldValue('taxCode', undefined);
    }
  }, [isTaxCodeEnable, setFieldValue]);

  if (!data || !data.me || !data.getCountries) {
    return null;
  }

  const { hasCRM } = data.me;
  const graphicalIdentities = data?.me?.graphicalIdentities.edges;

  return (
    <Form layout="vertical">
      {hasCRM && (
        <Form.Item label={t('app.common.contactType')} name="contactTypeId" required hasFeedback={false}>
          <Select getPopupContainer={trigger => trigger.parentNode} name="contactTypeId" size="large">
            {data.me.contactTypes.edges.map(contactType => (
              <Select.Option key={contactType.id} value={contactType.id} title={''}>
                {contactType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Flex gap="middle">
        <Form.Item label={t('app.common.firstname')} name="firstname" hasFeedback={false}>
          <TextInput
            name="firstname"
            placeholder={t('app.common.firstname')}
            size="large"
            setFieldValue={setFieldValue}
          />
        </Form.Item>
        <Form.Item label={t('app.common.lastname')} name="lastname" hasFeedback={false}>
          <TextInput
            name="lastname"
            placeholder={t('app.common.lastname')}
            size="large"
            setFieldValue={setFieldValue}
          />
        </Form.Item>
      </Flex>
      <Flex gap="middle">
        <Form.Item label={t('app.common.email')} name="email" hasFeedback={false}>
          <TextInput name="email" placeholder={t('app.common.email')} size="large" setFieldValue={setFieldValue} />
        </Form.Item>
        <Form.Item label={t('app.common.phone')} name="phone" hasFeedback={false}>
          <TextInput name="phone" placeholder={t('app.common.phone')} size="large" setFieldValue={setFieldValue} />
        </Form.Item>
      </Flex>
      {hasCRM && (
        <>
          <Flex gap="middle" align="center">
            <Form.Item label={t('app.common.birthdate')} name="birthdate" hasFeedback={false}>
              <DatePicker
                name="birthdate"
                placeholder={t('app.common.selectDate')}
                size="large"
                defaultPickerValue={dayjs('1985-06-01')}
              />
            </Form.Item>
            {values.birthdate && (
              <Form.Item
                label={t('app.contact.birthdayEmail')}
                name="birthdayEmail"
                className="ant-form-item--switch"
                hasFeedback={false}
              >
                <Switch name="birthdayEmail" />
              </Form.Item>
            )}
          </Flex>
          <Flex gap="middle" align="center">
            <Form.Item label={t('app.common.photo', { count: 1 })} name="photo" hasFeedback={false}>
              <Flex gap="middle">
                {image && <PhotoPreview src={image} />}
                <FileSelect
                  name="photo"
                  accept="image/png, image/jpeg, image/svg+xml"
                  handleOnChange={handleFileChange}
                />
              </Flex>
            </Form.Item>
            <Form.Item
              label={t('app.common.favorite')}
              name="favorite"
              className="ant-form-item--switch"
              hasFeedback={false}
            >
              <Switch name="favorite" />
            </Form.Item>
          </Flex>
        </>
      )}
      <Fieldset className="Fieldset" legend={t('app.common.address')}>
        <Flex gap="middle">
          <Form.Item label={t('app.common.street')} name="street" hasFeedback={false}>
            <TextInput name="street" placeholder={t('app.common.street')} size="large" setFieldValue={setFieldValue} />
          </Form.Item>
          <Form.Item label={t('app.common.zipcode')} name="zipcode" hasFeedback={false}>
            <TextInput
              name="zipcode"
              placeholder={t('app.common.zipcode')}
              size="large"
              setFieldValue={setFieldValue}
            />
          </Form.Item>
        </Flex>
        <Flex gap="middle">
          <Form.Item label={t('app.common.city')} name="city" hasFeedback={false}>
            <TextInput name="city" placeholder={t('app.common.city')} size="large" setFieldValue={setFieldValue} />
          </Form.Item>
        </Flex>
        <Flex gap="middle">
          {countryStates && countryStates.length > 0 && (
            <Form.Item label={t('app.common.state')} name="stateId" hasFeedback={false}>
              <Select
                name="stateId"
                placeholder={t('app.common.selectState')}
                getPopupContainer={trigger => trigger.parentNode}
                size="large"
                showSearch
                optionFilterProp="children"
              >
                {countryStates.map(state => (
                  <Select.Option key={state.id} value={state.id} title={''}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label={t('app.common.country')} name="countryId" hasFeedback={false}>
            <Select
              name="countryId"
              placeholder={t('app.common.selectCountry')}
              getPopupContainer={trigger => trigger.parentNode}
              size="large"
              showSearch
              optionFilterProp="children"
            >
              {data?.getCountries.edges.map(country => (
                <Select.Option key={country.id} value={country.id} title={''}>
                  {locale === 'fr' ? country.nameFr : country.nameEn}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
      </Fieldset>
      <Fieldset className="Fieldset" legend={t('app.common.company')}>
        <Flex gap="middle">
          <Form.Item label={t('app.common.name')} name="company" hasFeedback={false}>
            <TextInput name="company" placeholder={t('app.common.name')} size="large" setFieldValue={setFieldValue} />
          </Form.Item>
          <Form.Item label={t('app.common.vatNumber')} name="vat" hasFeedback={false}>
            <TextInput name="vat" placeholder={t('app.common.vatNumber')} size="large" setFieldValue={setFieldValue} />
          </Form.Item>
        </Flex>
      </Fieldset>
      {isTaxCodeEnable && (
        <Flex gap="middle">
          <Form.Item label={t('app.common.taxCode')} name="taxCode" hasFeedback={false}>
            <TextInput
              name="taxCode"
              placeholder={t('app.common.taxCode')}
              size="large"
              setFieldValue={setFieldValue}
            />
          </Form.Item>
        </Flex>
      )}
      {hasCRM && (
        <>
          <Flex gap="middle">
            {graphicalIdentities && graphicalIdentities.length > 0 && (
              <Form.Item label={t('app.common.graphicalIdentity')} name="graphicalIdentityId" hasFeedback={false}>
                <Select name="graphicalIdentityId" size="large" getPopupContainer={trigger => trigger.parentNode}>
                  <Select.Option
                    key={-1}
                    // Because ant design select item value of type Key can only be string or number and not null
                    // @ts-ignore
                    value={null}
                    title={''}
                  >
                    {t('app.common.default')}
                  </Select.Option>

                  {graphicalIdentities.map(graphicalIdenity => (
                    <Select.Option key={graphicalIdenity.id} value={graphicalIdenity.id} title={''}>
                      {graphicalIdenity.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Flex>
          <Form.Item label="Tags" name="tags" hasFeedback={false}>
            <Select
              name="tags"
              mode="tags"
              placeholder={t('app.common.selectAddTags')}
              size="large"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {data?.me?.tags.edges.map(tag => (
                <Select.Option key={tag.id} value={tag.name} title={''}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      <Flex gap="middle" justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditContactFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewContactPayload {
  values: NewContactFormValues;
  formikBag: FormikBag<NewContactFormProps, NewContactFormValues>;
}
export interface NewContactFormProps {
  onSubmit: (payload: NewContactPayload) => void;
  providerTypeId: number;
}

export interface EditContactPayload {
  values: EditContactFormValues;
  formikBag: FormikBag<EditContactFormProps, EditContactFormValues>;
}
export interface EditContactFormProps {
  onSubmit: (payload: EditContactPayload) => void;
  defaultValues: EditContactFormValues;
  providerTypeId: number;
  photoUrl?: string;
}

const contactSchema = yup.object().shape(
  {
    contactTypeId: yup.number().min(1).required(),
    firstname: yup.string().when(['company', 'lastname'], {
      is: undefined,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    lastname: yup.string().when(['firstname', 'company'], {
      is: undefined,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    email: yup.string().email().nullable(),
    phone: yup.string().nullable(),
    birthdate: yup.string().nullable(),
    birthdayEmail: yup.boolean(),
    photo: yup
      .mixed()
      .nullable()
      .test(
        'file',
        () => i18n.t('app.form.errors.fileToLarge', { limit: '500KB' }),
        value => {
          return value ? value.size <= 500000 : true;
        },
      ),
    favorite: yup.boolean(),
    street: yup.string().nullable(),
    zipcode: yup.string().nullable(),
    city: yup.string().nullable(),
    countryId: yup.number().nullable(),
    company: yup.string().when(['firstname', 'lastname'], {
      is: undefined,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    vat: yup.string().nullable(),
    prospectOriginId: yup.number().nullable(),
    graphicalIdentityId: yup.number().nullable(),
    tags: yup.array(),
    taxCode: yup.string(),
  },
  [
    ['lastname', 'company'],
    ['firstname', 'company'],
    ['firstname', 'lastname'],
  ],
);

const newContactSchema: yup.SchemaOf<NewContactFormValues> = contactSchema.defined();
const editContactSchema: yup.SchemaOf<EditContactFormValues> = contactSchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewContactForm = withFormik<NewContactFormProps, NewContactFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  validationSchema: newContactSchema,
  mapPropsToValues: () => ({
    contactTypeId: 0,
    firstname: undefined,
    lastname: undefined,
    company: undefined,
  }),
})(ContactForm);

export const EditContactForm = withFormik<EditContactFormProps, EditContactFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editContactSchema,
})(ContactForm);
