import { gql } from 'Operations/__generated__';

export const GET_DIGITAL_PRODUCTS = gql(/* GraphQL */ `
  query GetDigitalProducts($where: DigitalProductsWhereInput!) {
    getDigitalProducts(where: $where) {
      _count
      edges {
        id
        name
        digitalProductConfig {
          id
          downloadContent
        }
      }
    }
  }
`);
