import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import Editor from 'Components/Molecules/Form/Editor';
import Select from 'Components/Molecules/Form/Select';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { GET_FEATURE_CATEGORIES } from 'Operations/Queries/FeatureRequest/GetFeatureCategories';

export interface FeatureRequestFormValues {
  title: string;
  description: string;
  categoryId: number;
}

const FeatureRequest = <T extends FeatureRequestFormValues>({ isSubmitting }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  const { data: categoriesData } = useQuery(GET_FEATURE_CATEGORIES);

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.featureRequest.category')} name="categoryId" required hasFeedback={false}>
        <Select
          name="categoryId"
          size="large"
          optionFilterProp="children"
          loading={false}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {(categoriesData?.getFeaturesCategories || []).map(category => (
            <Select.Option key={category.id} value={category.id} title={''}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={t('app.common.title')} name="title" required hasFeedback={false}>
        <Input name="title" size="large" />
      </Form.Item>

      <Form.Item label={t('app.featureRequest.description')} name="description" hasFeedback={false}>
        <Editor name="description" heightMin="250" disabledFiles />
      </Form.Item>

      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.send')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface OnSubmitFeatureRequestPayload {
  values: FeatureRequestFormValues;
  formikBag: FormikBag<FeatureRequestFormProps, FeatureRequestFormValues>;
}

export interface FeatureRequestFormProps {
  onSubmit: (payload: OnSubmitFeatureRequestPayload) => void;
  defaultValues: FeatureRequestFormValues;
}

export const FeatureRequestForm = withFormik<FeatureRequestFormProps, FeatureRequestFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
  }),
  validationSchema: yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
    categoryId: yup.number().required(),
  }),
})(FeatureRequest);
