import React from 'react';

import I18n, { Scope, TranslateOptions } from 'i18n-js';

import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import nl from './locales/nl.json';

I18n.locale = navigator.language.split('-')[0] || 'en';
I18n.translations = {
  de,
  en,
  es,
  fr,
  it,
  nl,
};

I18n.defaultLocale = 'en';
I18n.fallbacks = true;

I18n.missingTranslation = function (scope) {
  if (scope.startsWith('app.message.error') || scope.indexOf('.error.') > 0) {
    if (scope.endsWith('title')) {
      return I18n.t('app.common.error');
    } else {
      return I18n.t('app.message.error.somethingWentWrong');
    }
  }
  return scope;
};

export type LocalizationContextType = {
  t: (scope: Scope, options?: TranslateOptions) => string;
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
  isInitialized: boolean;
};

export const LocalizationContext = React.createContext<LocalizationContextType>({
  t: I18n.t,
  locale: I18n.locale,
  setLocale: locale => locale,
  isInitialized: false,
});

export default I18n;
