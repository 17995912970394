import { useCallback, useContext, useMemo } from 'react';

import { MutationHookOptions, useMutation } from '@apollo/client';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { camelCase } from 'lodash';

import { NewGalleryFormPayload, NewGalleryFormValues } from 'Forms/Gallery';

import { LocalizationContext } from 'i18n';

import generateId from 'Helpers/GenerateId';
import getErrorCode from 'Helpers/GetErrorCode';

import {
  CreateGalleryMutation,
  Exact,
  GalleryAccessPolicy,
  GalleryCreateInputType,
  GalleryStatus,
  Locale
} from 'Operations/__generated__/graphql';

import { CREATE_GALLERY } from 'Operations/Mutations/Gallery/CreateGallery';

import { useModals } from './Modal';
import { useCurrentUser } from './useCurrentUser';

export const useGalleryModal = (
  options?: MutationHookOptions<CreateGalleryMutation, Exact<{ data: GalleryCreateInputType }>>,
) => {
  const { openModal, closeModal } = useModals();
  const { currentUser } = useCurrentUser();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const currentUserLocale = useMemo<Locale>(() => (currentUser?.me.locale as Locale) || Locale.EN, [currentUser]);

  const [createGalleryMutation] = useMutation(CREATE_GALLERY, options);

  const handleOnSubmit = useCallback(
    async ({
      values: {
        enableIncludedProduct: _enableIncludedProduct,
        enableMandatoryProduct: _enableMandatoryProduct,
        ...galleryData
      },
      formikBag,
    }: NewGalleryFormPayload) => {
      try {
        const gallery = await createGalleryMutation({ variables: { data: galleryData } });

        message.success(t('app.message.gallery.create.success'));
        formikBag.setSubmitting(false);
        closeModal();

        if (gallery.data?.createGallery) {
          navigate(`/app/galleries/${gallery.data.createGallery.id}`);
        }
      } catch (error) {
        console.log(error);

        const errorKey = camelCase(getErrorCode(error));

        if (errorKey === 'uniqConstraintFailed') {
          formikBag.setFieldError('url', t(`app.message.error.${errorKey}`, { field: 'url' }));
          message.error(t(`app.message.error.${errorKey}`, { field: 'url' }));
        } else {
          message.error(t('app.message.error.somethingWentWrong'));
        }

        formikBag.setSubmitting(false);
      }
    },
    [createGalleryMutation, message, t, navigate, closeModal],
  );

  const createGallery = useCallback(
    (initialData?: Partial<NewGalleryFormValues>) => {
      const defaultValues = {
        name: '',
        contactId: undefined,
        secondaryContactIds: [],
        status: GalleryStatus.OFFLINE,
        locale: currentUserLocale,
        availableAt: undefined,
        expiredAt: undefined,
        shootedAt: undefined,
        accessPolicy: GalleryAccessPolicy.PUBLIC,
        accessCode: generateId({ size: 6 }),
        inPortfolio: false,
        isShopEnable: false,
        includedProductId: undefined,
        includedPhotosCount: 10,
        hasAllPhotosIncluded: false,
        hasAllPhotosIncludedForGuest: false,
        ...initialData,
      };

      openModal('GALLERY', {
        name: 'new',
        onSubmit: handleOnSubmit,
        defaultValues,
      });
    },
    [currentUser, openModal, handleOnSubmit],
  );

  return { createGallery };
};
