import AccessModal from 'Components/Molecules/Modals/AccessModal';
import AddPhotosToOrderModal from 'Components/Molecules/Modals/AddPhotosToOrderModal';
import CatalogModal from 'Components/Molecules/Modals/CatalogModal';
import ContactModal from 'Components/Molecules/Modals/ContactModal';
import DownloadFavoritePhotosModal from 'Components/Molecules/Modals/DownloadFavoritePhotosModal';
import DownloadLaboPhotosModal from 'Components/Molecules/Modals/DownloadLaboPhotosModal';
import DownloadPhotosModal from 'Components/Molecules/Modals/DownloadPhotosModal';
import EmailTemplateModal from 'Components/Molecules/Modals/EmailTemplateModal';
import FeatureRequestModal from 'Components/Molecules/Modals/FeatureRequestModal';
import GalleryModal from 'Components/Molecules/Modals/GalleryModal';
import GalleryPresetModal from 'Components/Molecules/Modals/GalleryPresetModal';
import OptionCategoryModal from 'Components/Molecules/Modals/OptionCategoryModal';
import PaymentModal from 'Components/Molecules/Modals/PaymentModal';
import PricingPlanModal from 'Components/Molecules/Modals/PricingPlanModal';
import ProductCategoryModal from 'Components/Molecules/Modals/ProductCategoryModal';
import ProductModal from 'Components/Molecules/Modals/ProductModal';
import ProductOptionModal from 'Components/Molecules/Modals/ProductOptionModal';
import SendEmailModal from 'Components/Molecules/Modals/SendEmailModal';
import WatermarkModal from 'Components/Molecules/Modals/WatermarkModal';

const modals = {
  CONTACT: ContactModal,
  GALLERY: GalleryModal,
  WATERMARK: WatermarkModal,
  EMAIL_TEMPLATE: EmailTemplateModal,
  SEND_EMAIL: SendEmailModal,
  PRICING_PLAN: PricingPlanModal,
  PAYMENT: PaymentModal,
  FEATURE_REQUEST: FeatureRequestModal,
  GALLERY_PRESET: GalleryPresetModal,
  CATALOG: CatalogModal,
  PRODUCT: ProductModal,
  PRODUCT_CATEGORY: ProductCategoryModal,
  PRODUCT_OPTION: ProductOptionModal,
  OPTION_CATEGORY: OptionCategoryModal,
  DOWNLOAD_PHOTOS: DownloadPhotosModal,
  DOWNLOAD_LABO_PHOTOS: DownloadLaboPhotosModal,
  DOWNLOAD_FAVORITE_PHOTOS: DownloadFavoritePhotosModal,
  ACCESS_CODE: AccessModal,
  ADD_PHOTOS_TO_ORDER_MODAL: AddPhotosToOrderModal,
};

export default modals;
