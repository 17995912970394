import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex, Steps } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, InputNumber, Switch } from 'formik-antd';
import { orderBy, round, uniqBy } from 'lodash';

import Fieldset from 'Components/Atoms/Fieldset';
import Icon from 'Components/Atoms/Icon';
import Legend from 'Components/Atoms/Legend';
import Text from 'Components/Atoms/Text';

import DebounceNumberInput from 'Components/Molecules/Form/DebounceNumberInput';
import Editor from 'Components/Molecules/Form/Editor';
import FileSelect from 'Components/Molecules/Form/FileSelect';
import Select from 'Components/Molecules/Form/Select';

import { NewPricingPlanFormValues, newPricingPlanSchema } from 'Forms/PricingPlan';
import PricingPlanFormContent from 'Forms/PricingPlan/PricingPlanFormContent';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import { GET_FOTOSTUDIO_PROVIDERS } from 'Operations/Queries/Contact/GetFotostudioProviders';

import { useCurrency } from 'Hooks/useCurrency';

import yup from 'Services/YupService';

import { OrderType, PricingPlanType, ProductCategoryCharacteristic } from 'Operations/__generated__/graphql';

import { GET_CONTACT } from 'Operations/Queries/Contact/GetContact';
import { GET_CONTACTS } from 'Operations/Queries/Contact/GetContacts';
import { GET_PRODUCT_CATEGORIES } from 'Operations/Queries/ProductCategory/GetProductCategories';
import { GET_PRODUCT_CATEGORY } from 'Operations/Queries/ProductCategory/GetProductCategory';
import { ME } from 'Operations/Queries/User/Me';

export interface NewPhotoSize {
  width: number;
  height: number;
}

const ProductFields = ['name', 'description', 'categoryId', 'previewImage'];
const FormParts = { product: ProductFields };

export interface NewProductContentFormValues {
  presetProductId?: number;
  previewImage?: Blob;
  previewImageUrl?: string;
  name: string;
  categoryId: number;
  contactId?: number;
  maxPhotoCount: number;
  orderedByFotostudio: boolean;
  description?: string;
  photoSizes: NewPhotoSize[];
  isPreconfiguredProduct: boolean;
  isFotostudioProduct: boolean;
  enableCrop: boolean;
  isDigital: boolean;
  pricingPlanId?: number;
  weight?: number | null;
  profitMarginPercent?: number | null;
  withNewPricingPlan?: boolean;
}

export interface EditProductContentFormValues extends NewProductContentFormValues {
  id: number;
}

export interface NewProductFormValues extends NewPricingPlanFormValues {
  product: NewProductContentFormValues;
}

export interface EditProductFormValues {
  product: EditProductContentFormValues;
}

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: ${Metrics.baseMargin}px;
`;

const FormItemStyled = styled(Form.Item)`
  max-width: 200px;
`;

const FieldsetStyled = styled(Fieldset)`
  flex: 1;
`;

const StepsStyled = styled(Steps)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const PreviousButton = styled(Button)`
  margin-right: ${Metrics.smallMargin}px;
`;

const ProfitMarginText = styled(Text)`
  margin-top: ${Metrics.tinyMargin}px;
`;

const PER_PAGE = 20;
const EDGES_PARAMS = {
  perPage: PER_PAGE,
  order: OrderType.ASC,
};
const { Step } = Steps;

export interface ProductFormProps {
  isAdmin: boolean;
  minPrice?: number;
}

const ProductForm = <T extends EditProductFormValues | NewProductFormValues>({
  isSubmitting,
  values,
  setFieldValue,
  validateForm,
  isAdmin,
  minPrice,
}: FormikProps<T> & ProductFormProps) => {
  const { t } = useContext(LocalizationContext);

  const contactPage = useRef(1);
  const contactSearchTerms = useRef<string | undefined>(undefined);
  const productCategoryPage = useRef(1);
  const productCategorySearchTerms = useRef<string | undefined>(undefined);

  const [image, setImage] = useState<string | undefined>(values.product.previewImageUrl);
  const [currentStep, setCurrentStep] = useState<number>(0);
  // Categories are filtered to prevent adding product to user digital category. So, API total and app total can defer.
  const [shouldHideLoadMore, setShouldHideLoadMore] = useState<boolean>(false);

  const { formatCurrency } = useCurrency();

  const isReadOnly = useMemo(() => {
    return !!(
      values.product.presetProductId &&
      (values.product.isPreconfiguredProduct || values.product.isFotostudioProduct)
    );
  }, [values.product.isFotostudioProduct, values.product.isPreconfiguredProduct, values.product.presetProductId]);

  const isEditing = !!(values as EditProductFormValues).product.id;

  const { data: currentUser } = useQuery(ME);

  // Fetch selected product category to show it even if not in the current pagination
  const { data: productCategoryData } = useQuery(GET_PRODUCT_CATEGORY, {
    fetchPolicy: 'cache-first',
    skip: !values.product.categoryId,
    variables: {
      where: {
        id: values.product.categoryId,
      },
    },
  });

  const CATEGORIES_PARAMS = useMemo(
    () => ({
      ...EDGES_PARAMS,
      characteristics: [
        (values.product.isPreconfiguredProduct || values.product.isFotostudioProduct) &&
        isAdmin &&
        (!isReadOnly || !values.product.presetProductId)
          ? ProductCategoryCharacteristic.ADMIN
          : ProductCategoryCharacteristic.USER,
      ],
    }),
    [
      isAdmin,
      isReadOnly,
      values.product.isFotostudioProduct,
      values.product.isPreconfiguredProduct,
      values.product.presetProductId,
    ],
  );

  const {
    data: productCategoriesData,
    loading: isProductCategoriesLoading,
    fetchMore: fetchMoreProductCateogries,
    refetch: refetchProductCategories,
  } = useQuery(GET_PRODUCT_CATEGORIES, {
    variables: {
      where: {
        ...CATEGORIES_PARAMS,
        page: 1,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: contactsData,
    loading: isContactsLoading,
    fetchMore: fetchMoreContacts,
    refetch: refetchContacts,
  } = useQuery(GET_CONTACTS, {
    skip: values.product.isPreconfiguredProduct || values.product.isFotostudioProduct,
    variables: {
      where: {
        ...EDGES_PARAMS,
        page: 1,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: fotostudioProvidersData, loading: isFSProvidersLoading } = useQuery(GET_FOTOSTUDIO_PROVIDERS, {
    skip: !values.product.isPreconfiguredProduct && !values.product.isFotostudioProduct,
    fetchPolicy: 'cache-and-network',
  });

  const { data: contactData } = useQuery(GET_CONTACT, {
    skip: !values.product.contactId,
    variables: {
      where: {
        id: values.product.contactId as number,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (values.product.photoSizes.length !== values.product.maxPhotoCount || !values.product.enableCrop) {
      setFieldValue('product[orderedByFotostudio]', false);
    }
  }, [values.product.photoSizes, values.product.maxPhotoCount, values.product.enableCrop, setFieldValue]);

  useEffect(() => {
    if (!values.product.enableCrop) {
      setFieldValue('product[photoSizes]', []);
    }
  }, [setFieldValue, values.product.enableCrop]);

  useEffect(() => {
    const shouldAdd = values.product.photoSizes.length < values.product.maxPhotoCount;
    const diff = Math.abs(values.product.maxPhotoCount - values.product.photoSizes.length);

    if (diff > 0 && values.product.enableCrop) {
      if (shouldAdd) {
        const newPhotoSizes = [];

        for (let index = 0; index <= diff - 1; index++) {
          newPhotoSizes.push({
            width: undefined,
            height: undefined,
          });
        }

        setFieldValue('product[photoSizes]', [...values.product.photoSizes, ...newPhotoSizes]);
      } else {
        const newSizes = [...values.product.photoSizes];
        newSizes.splice(newSizes.length - diff, diff);
        setFieldValue('product[photoSizes]', newSizes);
      }
    }
  }, [setFieldValue, values.product.enableCrop, values.product.maxPhotoCount, values.product.photoSizes]);

  useEffect(() => {
    if (productCategoryData && productCategoryData.getProductCategory.isDigital) {
      setFieldValue('product[enableCrop]', false);
      setFieldValue('product[contactId]', undefined);
      setFieldValue('product[maxPhotoCount]', 1);
      setFieldValue('product[isDigital]', true);
      setFieldValue('product[weight]', undefined);
    } else {
      setFieldValue('product[isDigital]', false);
    }
  }, [productCategoryData, setFieldValue]);

  // Merge the paginated contacts
  const contacts = useMemo(() => {
    const mergedContacts = [...(contactsData?.getContacts.edges || [])];

    if (contactData?.getContact) {
      mergedContacts.push(contactData.getContact);
    }

    if (values.product.isPreconfiguredProduct || values.product.isFotostudioProduct) {
      fotostudioProvidersData?.getFotostudioProviders.edges.forEach(contact => mergedContacts.push(contact));
    }

    return orderBy(uniqBy(mergedContacts, 'id'), [({ displayName }) => displayName.toLocaleLowerCase()]);
  }, [
    contactData?.getContact,
    contactsData?.getContacts.edges,
    fotostudioProvidersData?.getFotostudioProviders.edges,
    values.product.isFotostudioProduct,
    values.product.isPreconfiguredProduct,
  ]);
  const contactCount = useMemo(
    () =>
      (contactsData?.getContacts?._count ?? 0) +
      (contactData?.getContact
        ? contactsData?.getContacts.edges.map(c => c.id).includes(contactData?.getContact.id)
          ? 0
          : 1
        : 0),
    [contactData?.getContact, contactsData?.getContacts?._count, contactsData?.getContacts.edges],
  );

  const productCategoriesCount = useMemo(
    () => productCategoriesData?.getProductCategories._count || 0,
    [productCategoriesData?.getProductCategories._count],
  );
  const productCategories = useMemo(() => {
    let mergedProductCategories = [...(productCategoriesData?.getProductCategories.edges || [])];
    const digitalTotal = productCategoriesData
      ? productCategoriesData?.getProductCategories.edges.filter(c => c.isDigital).length
      : 0;

    if (productCategoryData?.getProductCategory) {
      mergedProductCategories.push(productCategoryData?.getProductCategory);
    }

    if (isReadOnly) {
      mergedProductCategories = mergedProductCategories.filter(c => !c.isDigital);
    }

    const filteredProductCategories = orderBy(uniqBy(mergedProductCategories, 'id'), [
      ({ name }) => name.toLocaleLowerCase(),
    ]);

    if (isReadOnly && digitalTotal > 0 && productCategoriesCount - digitalTotal === filteredProductCategories.length) {
      setShouldHideLoadMore(true);
    }

    return filteredProductCategories;
  }, [isReadOnly, productCategoriesCount, productCategoriesData, productCategoryData?.getProductCategory]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSearchContact = useCallback(
    async (searchTerms: string) => {
      if (!values.product.isPreconfiguredProduct && !values.product.isFotostudioProduct) {
        contactPage.current = 1;
        contactSearchTerms.current = searchTerms.length > 0 ? searchTerms : undefined;
        return refetchContacts({
          where: {
            ...EDGES_PARAMS,
            page: contactPage.current,
            search: contactSearchTerms.current,
            contactTypeIds: currentUser ? [currentUser?.me.providerTypeId] : undefined,
          },
        });
      }
    },
    [currentUser, refetchContacts, values.product.isFotostudioProduct, values.product.isPreconfiguredProduct],
  );

  const handleLoadMoreContacts = useCallback(() => {
    if (!values.product.isPreconfiguredProduct && !values.product.isFotostudioProduct) {
      contactPage.current += 1;
      fetchMoreContacts({
        variables: {
          where: {
            ...EDGES_PARAMS,
            page: contactPage.current,
            search: contactSearchTerms.current,
          },
        },
      });
    }
  }, [fetchMoreContacts, values.product.isFotostudioProduct, values.product.isPreconfiguredProduct]);

  const handleSearchProductCategory = useCallback(
    async (searchTerms: string) => {
      productCategoryPage.current = 1;
      productCategorySearchTerms.current = searchTerms.length > 0 ? searchTerms : undefined;
      return refetchProductCategories({
        where: {
          ...CATEGORIES_PARAMS,
          page: productCategoryPage.current,
          search: productCategorySearchTerms.current,
        },
      });
    },
    [CATEGORIES_PARAMS, refetchProductCategories],
  );

  const handleLoadMoreProductCategory = useCallback(() => {
    productCategoryPage.current += 1;
    fetchMoreProductCateogries({
      variables: {
        where: {
          ...CATEGORIES_PARAMS,
          page: productCategoryPage.current,
          search: productCategorySearchTerms.current,
        },
      },
    });
  }, [CATEGORIES_PARAMS, fetchMoreProductCateogries]);

  const handleNextStep = useCallback(async () => {
    const formPart = FormParts.product;
    const validation = await validateForm();
    const result = validation.product;

    let hasError = false;

    formPart.forEach(field => {
      if (result && (result as { [key: string]: string | undefined })[field] !== undefined) {
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    setCurrentStep(currentStep + 1);
  }, [currentStep, validateForm]);

  return (
    <>
      {values?.product.isDigital && !!values?.product.withNewPricingPlan && (
        <StepsStyled current={currentStep}>
          <Step title={t('app.common.products', { count: 1 })} />
          <Step title={t('app.common.pricingPlan')} />
        </StepsStyled>
      )}
      <Form layout="vertical">
        {(!values.product.isDigital || (values.product.isDigital && currentStep === 0)) && (
          <>
            {!!values.product.presetProductId &&
              (values.product.isFotostudioProduct || (values.product.isPreconfiguredProduct && !isEditing)) && (
                <Flex align="center">
                  <Form.Item
                    label={t('app.common.profitMarginPercent')}
                    name="product[profitMarginPercent]"
                    required
                    hasFeedback={false}
                  >
                    <Input
                      name="product[profitMarginPercent]"
                      placeholder={t('app.common.profitMarginPercent')}
                      type="number"
                      size="large"
                      addonAfter="%"
                      autoFocus
                    />
                    {minPrice && (
                      <>
                        <ProfitMarginText size="medium">
                          {
                            // ie: 20 € + 50 % of profit margin = 30 €.
                            `${formatCurrency(minPrice)} + ${values.product.profitMarginPercent || 0} ${t(
                              'app.products.profitMarginPercent.percent',
                            )} = 
                          ${formatCurrency(
                            round(minPrice * (1 + (values.product.profitMarginPercent || 0) / 100), 2),
                          )}.`
                          }
                        </ProfitMarginText>
                        <ProfitMarginText size="medium" weight="bold">
                          {t('app.products.profitMarginPercent.inYourPocket', {
                            amount: formatCurrency(
                              round(minPrice * (1 + (values.product.profitMarginPercent || 0) / 100) - minPrice, 2),
                            ),
                          })}
                        </ProfitMarginText>
                      </>
                    )}
                  </Form.Item>
                </Flex>
              )}
            <Flex align="center" gap="middle">
              <Form.Item label={t('app.common.name')} name="product[name]" required hasFeedback={false}>
                <Input name="product[name]" placeholder={t('app.common.name')} size="large" disabled={isReadOnly} />
              </Form.Item>
              <Form.Item label={t('app.common.category')} name="product[categoryId]" required hasFeedback={false}>
                <Select
                  name="product[categoryId]"
                  size="large"
                  onSearch={handleSearchProductCategory}
                  optionFilterProp="children"
                  loadMore={handleLoadMoreProductCategory}
                  hasFetchAll={productCategoriesCount <= productCategories.length || shouldHideLoadMore}
                  loading={isProductCategoriesLoading}
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch
                >
                  {productCategories.map(category => (
                    <Select.Option key={category.id} value={category.id} title={''}>
                      {!values.product.presetProductId &&
                      (values.product.isPreconfiguredProduct || values.product.isFotostudioProduct)
                        ? t(`app.categories.${category.name.toLowerCase()}`)
                        : category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Flex>
            <Flex align="center" gap="middle">
              {!values?.product.isDigital && (
                <>
                  <Form.Item label={t('app.common.provider')} name="product[contactId]" required hasFeedback={false}>
                    <Select
                      name="product[contactId]"
                      size="large"
                      onSearch={handleSearchContact}
                      optionFilterProp="children"
                      loadMore={handleLoadMoreContacts}
                      hasFetchAll={contactCount <= contacts.length}
                      loading={isContactsLoading || isFSProvidersLoading}
                      getPopupContainer={trigger => trigger.parentNode}
                      showSearch
                      disabled={isReadOnly}
                    >
                      {contacts.map(contact => (
                        <Select.Option key={contact.id} value={contact.id} title={''}>
                          {contact.displayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={t('app.common.photoCount')}
                    name="product[maxPhotoCount]"
                    required
                    hasFeedback={false}
                  >
                    <DebounceNumberInput
                      name="product[maxPhotoCount]"
                      placeholder={t('app.common.photoCount')}
                      size="large"
                      min={1}
                      max={500}
                      disabled={isReadOnly}
                      debounceTime={250}
                    />
                  </Form.Item>
                </>
              )}
            </Flex>
            {!values.product.isDigital && (!isReadOnly || (isReadOnly && values.product.enableCrop)) && (
              <>
                <Flex vertical>
                  <Text size="medium">
                    <Icon name="tips" size={24} /> {t('app.products.printSizeDescription.title')}
                  </Text>
                  <ul>
                    <li>{t('app.products.printSizeDescription.text1')}</li>
                  </ul>
                </Flex>
                <FieldsetStyled className={`Fieldset ${!values.product.enableCrop ? 'Fieldset--NoContent' : ''}`}>
                  <Legend>
                    {!isReadOnly && (
                      <FormItemStyled
                        name="product[enableCrop]"
                        label={t('app.products.printSize')}
                        className="ant-form-item--switch"
                      >
                        <Switch name="product[enableCrop]" />
                      </FormItemStyled>
                    )}
                    {isReadOnly && (
                      <Text size="small" weight="bold">
                        {t('app.products.printSize')}
                      </Text>
                    )}
                  </Legend>
                  {values.product.enableCrop &&
                    values.product.photoSizes.map((_size, index) => (
                      <Fieldset
                        key={index}
                        className="Fieldset Fieldset--asChildren"
                        legend={`${t('app.common.photo', { count: 1 })} ${index + 1}`}
                      >
                        <Flex align="center" gap="middle">
                          <Form.Item
                            label={t('app.common.height')}
                            name={`product.photoSizes[${index}][height]`}
                            hasFeedback={false}
                          >
                            <InputNumber
                              name={`product.photoSizes[${index}][height]`}
                              type="number"
                              size="large"
                              min={0}
                              addonAfter="cm"
                              disabled={isReadOnly}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t('app.common.width')}
                            name={`product.photoSizes[${index}][width]`}
                            hasFeedback={false}
                          >
                            <InputNumber
                              name={`product.photoSizes[${index}][width]`}
                              type="number"
                              size="large"
                              min={0}
                              addonAfter="cm"
                              disabled={isReadOnly}
                            />
                          </Form.Item>
                        </Flex>
                      </Fieldset>
                    ))}
                </FieldsetStyled>
              </>
            )}
            {!values?.product.isDigital && (
              <Flex align="center">
                <Form.Item label={t('app.common.weight')} name="product[weight]" hasFeedback={false}>
                  <InputNumber
                    name={`product[weight]`}
                    type="number"
                    min={0}
                    size="large"
                    addonAfter="g"
                    disabled={isReadOnly}
                  />
                </Form.Item>
              </Flex>
            )}
            <Flex align="center">
              <Form.Item label={t('app.common.description')} name="product[description]" hasFeedback={false}>
                <Editor disabledFiles name="product[description]" />
              </Form.Item>
            </Flex>
            {!isReadOnly && (
              <Flex align="center">
                <Form.Item
                  name="product[previewImage]"
                  label={`${t('app.common.productImage')} (${t('app.common.maxResolution', {
                    resolution: '1620x1080',
                  })} - ${t('app.common.ratio', { ratio: '3/2' })})`}
                  hasFeedback={false}
                >
                  <FileSelect
                    name="product[previewImage]"
                    accept="image/png, image/jpeg, image/svg+xml"
                    handleOnChange={handleFileChange}
                  />
                </Form.Item>
              </Flex>
            )}
            {image && <Image src={image} />}
          </>
        )}
        {values?.product.isDigital && currentStep === 1 && !isEditing && (
          <PricingPlanFormContent values={values as NewProductFormValues} setFieldValue={setFieldValue} />
        )}

        <Flex justify="flex-end">
          {values?.product.isDigital && !!values?.product.withNewPricingPlan && currentStep === 0 && (
            <Button htmlType="button" type="primary" size="large" onClick={handleNextStep}>
              {t('app.actions.next')}
            </Button>
          )}
          {values?.product.isDigital && currentStep === 1 && (
            <PreviousButton htmlType="button" size="large" onClick={() => setCurrentStep(currentStep - 1)}>
              {t('app.actions.previous')}
            </PreviousButton>
          )}
          {(!values?.product.isDigital ||
            !values?.product?.withNewPricingPlan ||
            (values?.product.isDigital && currentStep === 1)) && (
            <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
              {t(isEditing ? 'app.common.edit' : 'app.common.add')}
            </Button>
          )}
        </Flex>
      </Form>
    </>
  );
};

export interface NewProductFormPayload {
  values: NewProductFormValues;
  formikBag: FormikBag<NewProductFormProps, NewProductFormValues>;
}

export interface NewProductFormProps extends ProductFormProps {
  onSubmit: (payload: NewProductFormPayload) => void;
  defaultValues: NewProductFormValues;
}

export interface EditProductFormPayload {
  values: EditProductFormValues;
  formikBag: FormikBag<EditProductFormProps, EditProductFormValues>;
}

export interface EditProductFormProps extends ProductFormProps {
  onSubmit: (payload: EditProductFormPayload) => void;
  defaultValues: EditProductFormValues;
}

const newPhotoSizeSchema = yup.object({
  width: yup.number().min(1).required(),
  height: yup.number().min(1).required(),
});

const newPhotoSizesSchema = yup.array().of(newPhotoSizeSchema);

const ProductSchema = yup.object({
  presetProductId: yup.number(),
  name: yup.string().trim().required(),
  previewImage: yup.mixed().test(
    'previewImage',
    () => i18n.t('app.form.errors.fileToLarge', { limit: '5MB' }),
    value => {
      return value ? value.size <= 5000000 : true;
    },
  ),
  previewImageUrl: yup.string(),
  categoryId: yup.number().required(),
  contactId: yup.number().when('isDigital', {
    is: false,
    then: yup.number().required(),
    otherwise: undefined,
  }),
  description: yup.string(),
  maxPhotoCount: yup.number().required(),
  orderedByFotostudio: yup.boolean().required(),
  isPreconfiguredProduct: yup.boolean().required(),
  isFotostudioProduct: yup.boolean().required(),
  enableCrop: yup.boolean().required(),
  isDigital: yup.boolean().required(),
  photoSizes: newPhotoSizesSchema,
  withNewPricingPlan: yup.boolean(),
  pricingPlanId: yup.number().when(['isDigital', 'withNewPricingPlan', 'id'], {
    is: (isDigital: boolean, withNewPricingPlan?: boolean, id?: number) => isDigital && !!withNewPricingPlan && id,
    then: yup.number().required(),
    otherwise: undefined,
  }),
  weight: yup.number().nullable().integer().min(0),
  profitMarginPercent: yup.mixed().when(['isFotostudioProduct', 'isPreconfiguredProduct', 'presetProductId', 'id'], {
    is: (isFotostudioProduct: boolean, isPreconfiguredProduct: boolean, presetProductId?: number, id?: number) =>
      (isFotostudioProduct || (isPreconfiguredProduct && !id)) && !!presetProductId,
    then: yup.number().min(1).required(),
    otherwise: undefined,
  }),
});

const NewProductContentSchema: yup.SchemaOf<NewProductContentFormValues> = ProductSchema.defined();
const EditProductContentSchema: yup.SchemaOf<EditProductContentFormValues> = ProductSchema.shape({
  id: yup.number().required(),
  previewImage: yup.mixed().test(
    'previewImage',
    () => i18n.t('app.form.errors.fileToLarge', { limit: '300KB' }),
    value => {
      return value ? value.size <= 300000 : true;
    },
  ),
}).defined();

const NewProductSchema = newPricingPlanSchema.shape({
  product: NewProductContentSchema,
});
const EditProductSchema = newPricingPlanSchema.shape({
  type: yup.mixed<PricingPlanType>(),
  product: EditProductContentSchema,
});

export const NewProductForm = withFormik<NewProductFormProps, NewProductFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,

    product: { ...defaultValues.product, previewImage: undefined, withNewPricingPlan: true },
  }),
  validationSchema: NewProductSchema,
})(ProductForm);

export const EditProductForm = withFormik<EditProductFormProps, EditProductFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    product: { ...defaultValues.product, previewImage: undefined, withNewPricingPlan: false },
  }),
  validationSchema: EditProductSchema,
})(ProductForm);
