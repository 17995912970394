import { gql } from 'Operations/__generated__';

export const GET_GALLERIES_ACCESS = gql(/* GraphQL */ `
  query GetAccessCodes($where: AccessCodesWhereType!) {
    getAccessCodes(where: $where) {
      edges {
        code
        createdAt
        galleryId
        contactId
        stats {
          ordersCount
          ordersAmount
        }
        contact {
          firstname
          lastname
          displayName @client
          email
        }
        name
        hasFullAccess
      }
      _count
    }
  }
`);
