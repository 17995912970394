import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { App, Button, Flex, Popconfirm, Table } from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';

import { compact, isArray } from 'lodash';

import Container from 'Components/Atoms/Container';
import Tag from 'Components/Atoms/Tag';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import OrderBulkEdit from 'Components/Molecules/Notification/OrderBulkEdit';
import OrderStatusTag from 'Components/Molecules/OrderStatusTag';
import SearchFilter from 'Components/Molecules/SearchFilter';

import { Colors, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useContactModal } from 'Hooks/useContactModal';
import { useCurrency } from 'Hooks/useCurrency';
import { useRoles } from 'Hooks/useRoles';

import dayjs from 'Services/DayjsService';

import {
  GetOrderListQuery,
  GetOrdersWhereInput,
  OrderItemHandler,
  OrderItemStatus,
  OrderType
} from 'Operations/__generated__/graphql';

import { CONVERT_INVOICE } from 'Operations/Mutations/Invoice/ConvertReceipt';
import { SET_ORDER_STATUS } from 'Operations/Mutations/Order/SetOrderStatus';

interface IdProps {
  id: number;
}

interface Props {
  ordersTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  crossAccounts: boolean;
  data?: GetOrderListQuery['getOrders']['edges'];
  isBordered?: boolean;
  defaultStatus?: OrderItemStatus;
  shouldHideGallery?: boolean;
  handleOnChange: (params: GetOrdersWhereInput) => void;
  handleDeleteConfirm?: (params: IdProps) => void;
  onRefetch?: () => void;
}

const statuses: string[] = [
  OrderItemStatus.WAITING_RETOUCH,
  OrderItemStatus.WAITING,
  OrderItemStatus.ORDERED,
  OrderItemStatus.TRANSIT,
  OrderItemStatus.DELIVERED,
  OrderItemStatus.CANCELLED,
];
const laboStatuses: string[] = [
  OrderItemStatus.WAITING,
  OrderItemStatus.ORDERED,
  OrderItemStatus.TRANSIT,
  OrderItemStatus.DELIVERED,
  OrderItemStatus.CANCELLED,
  OrderItemStatus.PROBLEM,
];

const OrderTable = ({
  loading,
  ordersTotal,
  data,
  paginationCurrent,
  paginationSize = 5,
  handleOnChange,
  defaultStatus,
  crossAccounts,
  onRefetch,
  isBordered = false,
  shouldHideGallery = false,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();
  const { openModal } = useModals();
  const { updateContact } = useContactModal();
  const { message, notification } = App.useApp();
  const { isLabo, isAdmin } = useRoles();
  const notificationIdentifier = useRef<string | null>(null);
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);

  const [setOrderStatus, { loading: isSetOrderStatusLoading }] = useMutation(SET_ORDER_STATUS);
  const [convertReceipt, { loading: isConvertingInvoice }] = useMutation(CONVERT_INVOICE);

  const handleOpenPaymentModal = useCallback(
    ({ invoiceId, invoiceAmount }: { invoiceId: number; invoiceAmount: number }) => {
      openModal('PAYMENT', {
        invoiceId,
        invoiceAmount,
        onSubmit: onRefetch,
      });
    },
    [openModal, onRefetch],
  );

  const copyToClipboard = useCallback(
    ({ value }: { value: string }) => {
      navigator.clipboard.writeText(value);
      message.success(t('app.common.clipboard.copy'));
    },
    [t],
  );

  const getStatusKey = useCallback(
    (status: string) => {
      if (!isLabo) {
        return status.toLowerCase();
      }
      switch (status) {
        case OrderItemStatus.ORDERED:
          return 'inProgress';
        case OrderItemStatus.TRANSIT:
          return 'shipped';
        default:
          return status.toLowerCase();
      }
    },
    [isLabo],
  );

  const columns = useMemo<ColumnsType<GetOrderListQuery['getOrders']['edges'][0]>>(
    () =>
      compact([
        {
          title: t('app.common.actions'),
          key: 'operation',
          width: 125,
          render: (_, record) => (
            <Container gap={Metrics.tinyMargin}>
              <RoundButton
                href={crossAccounts ? `/app/orders/fotostudio/${record.id}` : `/app/orders/${record.id}`}
                icon="edit"
                tooltipTitle={t('app.common.edit')}
              />
              {(isAdmin ||
                isLabo ||
                (record.__typename === 'OrderAdmin' && record.handlers.includes(OrderItemHandler.YOU))) && (
                <RoundButton
                  href={`/slip-control/${record.id}`}
                  target="_blank"
                  icon="document"
                  tooltipTitle={t('app.common.slipControl')}
                />
              )}
            </Container>
          ),
        },
        crossAccounts && {
          title: t('app.common.order', { count: 1 }),
          key: 'id',
          dataIndex: 'id',
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? Colors.primaryMain : undefined }} />,
          filterDropdown: props => {
            return <SearchFilter {...props} />;
          },
        },
        crossAccounts && {
          title: t('app.orders.seller'),
          key: 'seller',
          dataIndex: ['seller', 'companyName'],
          render(_, order) {
            if (order.__typename === 'OrderAdmin' && order.seller) {
              const { companyName, email } = order.seller;
              return (
                <Flex vertical gap="small">
                  <p>
                    <strong>{companyName}</strong>
                  </p>
                  {email && (
                    <Flex gap="small">
                      <RoundButton
                        icon="copy"
                        iconSize={10}
                        size="small"
                        onClick={() => copyToClipboard({ value: email })}
                      />
                      <div>{email}</div>
                    </Flex>
                  )}
                </Flex>
              );
            }
            return '-';
          },
        },
        !crossAccounts &&
          !shouldHideGallery && {
            title: t('app.common.gallery'),
            key: 'gallery',
            dataIndex: ['gallery', 'name'],
          },
        {
          title: t(`app.common.${crossAccounts ? 'recipient' : 'contact'}`),
          key: 'contact',
          render(_, record) {
            if (record.__typename === 'OrderAdmin') {
              let name = record.contact?.displayName || record.gallery?.contact?.displayName || '-';
              let email = record.contact?.email;
              let address: string | undefined | null;

              if (crossAccounts) {
                if (record.photographerMustReceiveOrder && !!record.seller) {
                  name = record.seller.companyName;
                  email = record.seller.email;
                  address = record.seller.companyAddress;
                }

                return (
                  <Flex vertical gap="small">
                    <Flex>
                      {address && (
                        <RoundButton
                          tooltipTitle={t('app.common.deliveryAddress')}
                          icon="location"
                          iconSize={10}
                          size="small"
                          onClick={() => copyToClipboard({ value: address || '' })}
                        />
                      )}
                      <strong>{name}</strong>
                    </Flex>
                    {!!email && (
                      <Flex gap="small">
                        <RoundButton
                          icon="copy"
                          iconSize={10}
                          size="small"
                          onClick={() => copyToClipboard({ value: email || '' })}
                        />
                        <div>{email}</div>
                      </Flex>
                    )}
                  </Flex>
                );
              }

              if (record.contact?.id && !crossAccounts) {
                const id = record.contact.id;
                return (
                  <Button type="link" onClick={() => updateContact(id)}>
                    {name}
                  </Button>
                );
              }
              return name;
            }
            return '-';
          },
        },
        !crossAccounts && {
          title: t('app.common.paymentStatus'),
          dataIndex: 'isPaid',
          width: 180,
          key: 'isPaid',
          render: (isPaid: boolean) => (
            <Container>
              <Tag color={isPaid ? Colors.success : Colors.waiting} size="small">
                {t(isPaid ? 'app.common.paid' : 'app.common.waitingForPayment')}
              </Tag>
            </Container>
          ),
        },
        {
          title: t('app.common.status'),
          key: 'status',
          dataIndex: 'status',
          width: 180,
          filters: defaultStatus
            ? undefined
            : (isLabo ? laboStatuses : statuses).map(status => ({
                text: t(`app.orders.orderItemStatus.${getStatusKey(status)}`),
                value: status,
              })),
          onFilter(value, record) {
            if (record.__typename === 'OrderAdmin' && value === record.status) {
              return true;
            }

            return false;
          },
          filterMultiple: false,
          render: status => {
            return (
              <Container>
                <OrderStatusTag itemStatus={status} />
              </Container>
            );
          },
        },
        !crossAccounts && {
          title: t('app.orders.handledBy'),
          key: 'handledBy',
          width: 100,
          dataIndex: 'orderedByFotostudio',
          render: (_, record) => {
            return (
              record.__typename === 'OrderAdmin' && (
                <Container direction="row" gap={Metrics.tinyMargin}>
                  {record.handlers.map(handler => (
                    <Tag
                      key={handler}
                      color={handler === OrderItemHandler.INCOGNITO ? Colors.crmMain : Colors.black}
                      size="small"
                    >
                      {handler === OrderItemHandler.INCOGNITO ? 'Fotostudio' : t(`app.common.${handler.toLowerCase()}`)}
                    </Tag>
                  ))}
                </Container>
              )
            );
          },
        },
        {
          title: t('app.common.createdAt'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: text => dayjs(text).format('LLL'),
        },
        crossAccounts && {
          title: t('app.common.shippedAt'),
          dataIndex: 'laboOrderProcessedAt',
          key: 'laboOrderProcessedAt',
          render: text => (text ? dayjs(text).format('LLL') : '-'),
        },
        !crossAccounts && {
          title: t('app.common.invoices', { count: 1 }),
          dataIndex: 'invoice',
          key: 'invoice',
          width: 200,
          render: (invoice: GetOrderListQuery['getOrders']['edges'][0]['invoice'] | null) => {
            if (!invoice) {
              return '-';
            }
            const { url, ttcPrice, id } = invoice;
            if (!id || !url || !ttcPrice) {
              return '-';
            }
            return (
              <Flex align="center" gap="small">
                <Text>{`${formatCurrency(ttcPrice)}`}</Text>
                <a href={url} target="_blank">
                  <RoundButton icon="download" tooltipTitle={t('app.common.download')} />
                </a>
                <RoundButton
                  icon="add"
                  tooltipTitle={t('app.invoices.createPayment')}
                  onClick={() => {
                    handleOpenPaymentModal({ invoiceId: id, invoiceAmount: ttcPrice });
                  }}
                />
                {invoice.isReceipt && (
                  <Popconfirm
                    title={t('app.invoices.convertConfirm')}
                    onConfirm={() => {
                      convertReceipt({
                        variables: { where: { id: invoice.id } },
                        onCompleted() {
                          message.success(t('app.invoices.convertSuccess'));
                        },
                      });
                    }}
                  >
                    <RoundButton
                      type="primary"
                      icon="transform-file"
                      tooltipTitle={t('app.invoices.convert')}
                      disabled={isConvertingInvoice}
                    />
                  </Popconfirm>
                )}
              </Flex>
            );
          },
        },
      ]),
    [
      copyToClipboard,
      crossAccounts,
      defaultStatus,
      formatCurrency,
      getStatusKey,
      handleOpenPaymentModal,
      isAdmin,
      isLabo,
      shouldHideGallery,
      t,
      updateContact,
    ],
  );

  const handleChangeStatus = useCallback(
    async ({ ids, status }: { ids: number[]; status: OrderItemStatus }) => {
      await Promise.allSettled(ids.map(id => setOrderStatus({ variables: { where: { id }, data: { status } } })));
      setSelectedRowsIds([]);
    },
    [setOrderStatus],
  );

  const rowSelection = useMemo<TableRowSelection<GetOrderListQuery['getOrders']['edges'][0]> | undefined>(
    () =>
      crossAccounts
        ? {
            type: 'checkbox',
            onChange(selectedRowKeys) {
              setSelectedRowsIds(selectedRowKeys as number[]);
            },
            selectedRowKeys: selectedRowsIds,
          }
        : undefined,
    [crossAccounts, selectedRowsIds],
  );

  useEffect(() => {
    if (selectedRowsIds.length > 0) {
      notificationIdentifier.current = 'edit-table';
      notification.open({
        key: notificationIdentifier.current,
        placement: 'bottomRight',
        duration: 0,
        className: 'ant-notification--bulk-edit',
        onClose: () => setSelectedRowsIds([]),
        message: (
          <OrderBulkEdit
            selectedRowsIds={selectedRowsIds}
            onChange={handleChangeStatus}
            loading={isSetOrderStatusLoading}
          />
        ),
      });
    } else {
      if (notificationIdentifier.current) {
        notification?.destroy(notificationIdentifier.current);
        notificationIdentifier.current = null;
        setSelectedRowsIds([]);
      }
    }
  }, [crossAccounts, handleChangeStatus, isSetOrderStatusLoading, selectedRowsIds, t]);

  useEffect(() => {
    return () => {
      if (notificationIdentifier.current) {
        notification?.destroy(notificationIdentifier.current);
        notificationIdentifier.current = null;
        setSelectedRowsIds([]);
      }
    };
  }, []);

  return (
    <>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data}
        columns={columns}
        loading={loading}
        showHeader
        pagination={{
          total: ordersTotal,
          pageSize: paginationSize,
          current: paginationCurrent,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
        onChange={(pagination, filters, sorter) => {
          let order: OrderType = OrderType.DESC;

          if (isArray(sorter)) {
            order = sorter[0].order === 'ascend' ? OrderType.ASC : OrderType.DESC;
          } else {
            order = sorter.order === 'ascend' ? OrderType.ASC : OrderType.DESC;
          }

          handleOnChange({
            page: pagination.current ? pagination.current : 1,
            perPage: pagination.pageSize ? pagination.pageSize : 20,
            order,
            status: filters.status ? OrderItemStatus[filters.status as unknown as OrderItemStatus] : undefined,
            search: filters.id ? `${filters.id}` : undefined,
          });
        }}
      />
    </>
  );
};

export default OrderTable;
